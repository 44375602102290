import React from "react";
import './Blogs.css';
import BlogsTitleCard from "./components/BlogsTitleCard/BlogsTitleCard";
import BlogsCard from "./components/BlogsCard/BlogsCard";

const Blogs = () => (
    <div className='Blogs'>
        <BlogsTitleCard/>
        <BlogsCard/>
    </div>
);

export default Blogs;
