import React from "react";
import {Col, Row} from "react-bootstrap";
import './CoreValue.css';

const Core = ({dotColor, title, label}) => (
    <Col md={4} sm={12} xs={12} className='content-spacer'>
        <div className='auto-margin m-md-0 dot-circle' style={{backgroundColor: dotColor}}/>
        <div className='content-spacer content-title label-align grey-font-color'>
            {title}
        </div>
        <div className='content-label label-align semi-black-font-color'>
            {label}
        </div>
    </Col>
);

const CoreValue = () => (
    <Col md={10} sm={12} xs={12} className='auto-margin card-spacer'>
        <div className='center-label card-title red-font-color'>
            Our Core Value
        </div>
        <Row>
            <Core dotColor={'#439D8E'} title={'Human Centredness'} label={'We focus on solving problems and find solutions that meet each person\'s needs. We provide our services by placing our clients at the center of the process'}/>
            <Core dotColor={'#EB7420'} title={'Fitness is Fun'} label={'We believe that getting a healthy body and mental should be done in joy. Because fitness is fun!'}/>
            <Core dotColor={'#DE1E30'} title={'Contributing to Community'} label={'We actively promote a culture of wellbeing in bringing our best selves to build communities of happy and healthy individuals who become a positive influence to each other'}/>
        </Row>
    </Col>
);

export default CoreValue;
