import React from "react";
import './DownloadForm.css';
import DownloadTitle from "./components/DownloadTitle/DownloadTitle";
import CWPDownload from "./components/CWPDownload/CWPDownload";

const DownloadForm = () =>(
    <div className='DownloadForm'>
        <DownloadTitle/>
        <CWPDownload/>
    </div>
);

export default DownloadForm;
