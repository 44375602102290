import React from "react";
import './LifestyleAssesment.css';
import LifestyleTitle from "./components/LifestyleTitle/LifestyleTitle";
import WhatIsLifestyle from "./components/WhatIsLifestyle/WhatIsLifestyle";
import WhatAssesmentCan from "./components/WhatAssesmentCan/WhatAssesmentCan";
import ContactUsCard from "../../CommonComponent/ContactUsCard/ContactUsCard";

const LifeStyleAssesment = () => (
    <div className='LifestyleAssesment'>
        <LifestyleTitle/>
        <WhatIsLifestyle/>
        <WhatAssesmentCan/>
        <div className='card-spacer'>
            <ContactUsCard label={'Let’s find your work life balance!'} />
            <ContactUsCard label={'Are you a wellness, nutrition, or mental health practitioner? Become our partner'} bgColor='#F1B61C' />
        </div>
    </div>
);

export default LifeStyleAssesment;
