export const programData = {
    headers: ["LIF MAX (12 MONTHS)", "LIF CORE (6 MONTHS)", "LIF FIT (3 MONTHS)", "TRIAL PROGRAM (1 MONTH)"],
    sections: [
        {
            title: "Program Package",
            rows: [
                { label: "No. of employees", values: ["Unlimited", "Unlimited", "Unlimited", "Unlimited"] },
                { label: "Body Composition Measurement", values: ["3 x 60 Pax", "2 x 60 Pax", "1 x 40 Pax", "1 x 30 Pax"] },
                { label: "Expert Consultation (Psychologist / Nutritionist)", values: ["40 Sessions", "30 Sessions", "20 Sessions", "-"] },
                { label: "Workout Class", values: ["3x", "3x", "1x", "1x"] },
                { label: "Health Talk", values: ["3X", "2X", "1X", "1x"] },
                { label: "Rewards from LIF", values: ["IDR 12 Millions", "IDR 6 Millions", "IDR 3 Millions", "IDR 1.5 Millions"] }
            ]
        },
        {
            title: "LIF App Features",
            rows: [
                { label: "Fitness Gamification", values: [true, true, true, true] },
                { label: "Real Time Leaderboard", values: [true, true, true, true] },
                { label: "Health Education Content", values: [true, true, true, true] },
                { label: "Health Behaviour Questionnaire", values: [true, true, true, true] },
                { label: "Connected to various wearables", values: [true, true, true, true] }
            ]
        },
        {
            title: "Program Support",
            rows: [
                { label: "Mendadak gerak", values: [true, true, true, false] },
                { label: "Healthy Lifestyle Guidebook", values: [true, false, false, false] },
                { label: "Program Campaign Banner", values: [true, false, false, false] },
                { label: "Merchandise", values: [true, false, false, false] }
            ]
        }
    ]
};
