import React from "react";
import './CSR.css';
import CsrTitle from "./components/CsrTitle/CsrTitle";
import WhatIsCSR from "./components/WhatIsCSR/WhatIsCSR";
import AdvantagesOfCSR from "./components/AdvantagesOfCSR/AdvantagesOfCSR";
import HowItWorks from "./components/HowItWorks/HowItWorks";
import ContactUsCard from "../../CommonComponent/ContactUsCard/ContactUsCard";
import GaleryCard from "./components/GaleryCard/GaleryCard";

const CSR=() =>(
    <div className='csr'>
        <CsrTitle/>
        <WhatIsCSR/>
        <AdvantagesOfCSR/>
        <HowItWorks/>
        <GaleryCard/>
        <div className='card-spacer'>
            <ContactUsCard label={'Let’s get started!'} />
            <ContactUsCard label={'Are you a wellness, nutrition or mental health practitioner? Become our partner'} bgColor='#F1B61C' />
        </div>
    </div>
);

export default CSR
