import React from 'react';
import PropTypes from 'prop-types';
import ImpactCard from "./components/ImpactCard/ImpactCard";
import UpperContent from "./components/UpperContent/UpperContent";
import WorkplaceChallenge from "./components/WorkplaceChallenge/WorkplaceChallenge";
import SolutionCard from "./components/SolutionCard/SolutionCard";
import PartnerCard from "./components/PartnerCard/PartnerCard";

const HomePage = () =>(
    <div>
        <UpperContent></UpperContent>
        <WorkplaceChallenge></WorkplaceChallenge>
        <ImpactCard></ImpactCard>
        <SolutionCard></SolutionCard>
        <PartnerCard></PartnerCard>
    </div>
);

HomePage.propTypes = {};
HomePage.defaultProps = {};

export default HomePage;
