import React from "react";
import PropTypes from "prop-types";
import '../../../App.css';
import './NumberingList.css';

const NumberingList = ({datas}) => (
    <div>
        {datas.map((value, i) => {
            return (
                <div className='column-container'>
                    <div className='number-margin'>{i+1+'.'}</div>
                    <div className='content-label content-label semi-black-font-color'>{value}</div>
                </div>
            )
        })}
    </div>
);

NumberingList.propTypes = {
    datas: PropTypes.array.isRequired,
};
NumberingList.defaultProps = {
};

export default NumberingList;
