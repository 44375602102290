import React, { Component } from "react";
var __html = require('../static/privacy.html.js');
var template = { __html: __html };

class PrivacyPolicyApp extends Component {
    render() {
        return (
            <div className="screen-share">
                <span dangerouslySetInnerHTML={template} />
            </div>
        );
    }
}
export default PrivacyPolicyApp;