import challengeImage from '../../../../assets/lp/challenge.png';
import reportImage from '../../../../assets/lp/monthly-report.png';
import commImage from '../../../../assets/lp/community.png';
import designEvryoneImg from '../../../../assets/lp/designed-to-everyone.png';


export const exampleCards = [
    {
        image: challengeImage,
        title: 'Craft Your Healthy Activities',
        label: 'Create measureable challenges and engaging events to encourage healthier lifestyles among your people',
        reverse: false
    },
    {
        image: reportImage,
        title: 'Track Your Program Performances',
        label: 'We are providing actionable intelligence based on real activities data. This information helps improve program, and achieve your company wellness goals',
        reverse: true
    },
    {
        image: commImage,
        title: 'Keep Your People Connected',
        label: 'Improve employee engagement in a fun way through community engagement powered by technology. In fact, fitness is more fun with friends',
        reverse: false
    },
    {
        image: designEvryoneImg,
        title: 'Designed for Everyone',
        label: 'LIF is designed for all people, regardless of geography, age, or job profile. Furthermore, our dedicated experts will help you achieve wellness success',
        reverse: true
    }
]
