import React, {useEffect} from 'react';
import './Pricing.css';
import {programData} from "./pricing-data";
import {Col} from "react-bootstrap";
import GeneralButton from "../CommonComponent/GeneralButton/GeneralButton";
import contactUsIcon from '../../assets/contact-us-icon.svg';
import lifCheckIcon from '../../assets/lif-check.svg';
import lifUnCheckIcon from '../../assets/lif-uncheck.svg';
import freeTracker from '../../assets/free-tracker.png';


const TablePricing = ({data}) => {
    return (
        <div className="table-container">
            <table className="program-table">
                {/* Header Row */}
                <thead>
                <tr className="program-header">
                    <th className="table-header">LIF PROGRAM</th>
                    {data.headers.map((header, index) => (
                        <th key={index} className="table-header">{header}</th>
                    ))}
                </tr>
                </thead>

                <tbody>
                {data.sections.map((section, sectionIndex) => (
                    <React.Fragment key={sectionIndex}>
                        {/* Section Header */}
                        <tr className="section-header">
                            <td colSpan={data.headers.length + 1}>{section.title}</td>
                        </tr>

                        {/* Rows */}
                        {section.rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>{row.label}</td>
                                {row.values.map((value, valueIndex) => (
                                    <td key={valueIndex}>
                                        {typeof value === "boolean" ? (value ?
                                            <img className='img-fluid' src={lifCheckIcon}/> :
                                            <img className='img-fluid' src={lifUnCheckIcon}/>) : value}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </React.Fragment>
                ))}
                </tbody>
            </table>
        </div>
    );
};

const PricingTitle = () => {
    return (
        <>
            <Col md={12} sm={12} xs={12}
                 className='d-flex flex-column mb-3 justify-content-center align-items-center title-image'>
                <div className='pricing-table-lbl text-center lif-tosca'>
                    LIF for everybody works with various wearables,
                </div>
                <div className='titleLbl text-center lif-raspberry'>STARTS FROM IDR 10 MIO!</div>
                <div className='mt-4'>
                    <GeneralButton icon={contactUsIcon} divider label='Schedule Consultation'
                                   onClickAction={() => window.open("https://api.whatsapp.com/send?phone=628999747479&text=Halo saya ingin bertanya tentang harga program")}/>
                </div>
                <Col md={4} sm={12} xs={12} className='text-center mt-5'>
                    <div className='pricing-table-lbl p-2'>
                        <span className='lif-raspberry'>Choose</span> from our service packages
                    </div>
                    <div>
                        A dedicated Program Manager and monitoring dashboard to ensure the success of your program
                    </div>
                </Col>

            </Col>
        </>
    )
}

const FreeTracker = () => {
    return (
        <>
            <div className='mt-3'>
                <img src={freeTracker} className='d-block m-auto img-free-tracker'/>
            </div>
            <div className='mt-4'>
                <GeneralButton icon={contactUsIcon} divider label='Book Meeting'
                               onClickAction={() => window.open("https://api.whatsapp.com/send?phone=628999747479&text=Halo saya ingin bertanya tentang harga program")}/>
            </div>
        </>
    )
}

const Pricing = () => {
    useEffect(() => {
        document.title = "LIF Corporate Wellness Program - Starts From IDR 10 Mio";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute("content", "Tailored Corporate Wellness Program designed to support your employees’ wellness. Starting from 10 Mio + Free Activity Tracker");
        } else {
            const newMetaDescription = document.createElement("meta");
            newMetaDescription.setAttribute("name", "description");
            newMetaDescription.setAttribute("content", "Tailored Corporate Wellness Program designed to support your employees’ wellness. Starting from 10 Mio + Free Activity Tracker");
            document.head.appendChild(newMetaDescription);
        }
    }, []);
    return (
        <Col md={12} sm={12} xs={12}
             className='d-flex flex-column pricing justify-content-center align-content-center align-items-center'>
            <PricingTitle/>
            <TablePricing data={programData}/>
            <FreeTracker/>
        </Col>
    )
};
export default Pricing;