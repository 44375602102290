import React from "react";
import PropTypes from "prop-types";
import './BulletList.css';

const BulletList = ({datas, dotColor, dotSize}) => (
    <div>
        {datas.map(value => {
            return (
                <div className='column-container'>
                    <div className='d-flex align-self-start dot-container'>
                        <div className='dot align-self-center'
                             style={{width: dotSize + 'px', height: dotSize + 'px', backgroundColor: dotColor}}></div>
                    </div>
                    <div className='content-label semi-black-font-color'>{value}</div>
                </div>
            )
        })}
    </div>
);

BulletList.propTypes = {
    datas: PropTypes.array,
    dotColor: PropTypes.string,
    dotSize: PropTypes.number,
};
BulletList.defaultProps = {
    dotColor: '#F1B61C',
    dotSize: 13
};

export default BulletList;
