import React from "react";
import axiosInstance from "../../../../CommonComponent/AxiosInstane";
import history from "../../../../CommonComponent/history";
import {Col, Row} from "react-bootstrap";
import BlogsListCard from "../../BlogsListCard/BlogsListCard";
import {isMobile} from 'react-device-detect';


class RandomBlogList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            blogData: []
        }
    }
    componentDidMount() {
        this.fetchRandomBlogData();
    }

    fetchRandomBlogData(){
        axiosInstance.get('api/website/random/3').then(json => {
            let datas = json.data.WebsiteBlogs;
            this.setState({blogData: datas});
        });
    }

    redirectIntoBlog(blogData) {
        let titleUrl = blogData.Title.toLowerCase().replace(/ /g, "-");
        titleUrl = encodeURIComponent(titleUrl);
        const url = `/blogs/${titleUrl}`;
        history.push(url);
    }

    blogLink(data) {
        let slug = data.Title.toLowerCase().replace(/ /g, "-");
        window.parent.location.href = "/blogs/"+slug;
    }

    render() {
        return(
            <div className='content-spacer'>
                <Row className='auto-margin'>
                    {this.state.blogData.map((blog, index) => {
                            return (
                                    <BlogsListCard key={index} width={isMobile ? 100 : 30} description={blog.Description}
                                                   image={blog.ImageURL} title={blog.Title}
                                                   onReadMore={() => this.blogLink(blog)}/>
                            )
                        }
                    )
                    }
                </Row>
            </div>
        )
    }
}

export default RandomBlogList
