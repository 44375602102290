import React from "react";
import './BlogsTitleCard.css';
import {Col, Row} from "react-bootstrap";
import blogsImg from '../../../../assets/blogs-title-img.svg';

const BlogsTitleCard = () => (
    <Col md={6} sm={12} xs={12} className='card-spacer auto-margin'>
        <Row>
            <Col md={2} sm={12} xs={12}>
                <img className='img-healthy auto-margin block-disp' src={blogsImg}/>
            </Col>
            <Col md={10} sm={12} xs={12} className='column-container mt-1'>
                <div className='align-self-center'>
                    <div className='large-page-title label-align red-font-color'>
                        HEALTHY LIVING
                    </div>
                    <div className='content-label semi-black-font-color label-align'>
                        A huge selection of health & fitness blog content, corporate wellness and transformation stories to help you stay healthy and happy!
                    </div>
                </div>
            </Col>
        </Row>
    </Col>
);

export default BlogsTitleCard;
