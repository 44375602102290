import React from "react";
import './Description.css';
import {Col, Row} from "react-bootstrap";
import lifImg from '../../../../assets/we-re-lif.svg';
import ourVisionImg from '../../../../assets/our-vision.svg';
import ourMissionImg from '../../../../assets/our-mission.svg';
import ReactTypingEffect from 'react-typing-effect';


const Description = () => (
    <Col md={10} className='auto-margin card-spacer'>
        <Row>
            <Col md={6} sm={12} xs={12}>
                <div className='column-container'>
                    <div className='d-flex flex-row auto-margin'>
                        <img className='top-img' src={lifImg}/>
                        <div className='we-re-lif-lbl red-font-color align-self-center'>
                            <ReactTypingEffect
                                text={["HI. we're LIF"]} cursor={' '}
                            />
                        </div>
                    </div>
                </div>
            </Col>
            <Col md={6} sm={12} xs={12} className='column-container'>
                <div className='content-label mt-3 semi-black-font-color label-align align-self-center'>
                    A Platform to encourage employees toward active and healthy living in the workplace through
                    technology. LIF is about Fitness and Fun. Fitness can be Fun and Fun make us all happier and
                    healthier.
                </div>
            </Col>
        </Row>
        <Row className='card-spacer'>
            <Col md={6} sm={12} xs={12}>
                <div className='d-flex flex-row max-height'>
                    <img className='block-disp align-self-center second-img auto-margin' src={ourVisionImg}/>
                </div>
            </Col>
            <Col md={6} sm={12} xs={12} className='column-container'>
                <div className='align-self-center'>
                    <div className='card-title mt-3 red-font-color label-align'>
                        Our Vision
                    </div>
                    <div className='content-label semi-black-font-color label-align'>
                        To build communities of happy and healthy individuals who become active contributors and
                        influencers
                        in society.
                    </div>
                </div>
            </Col>
        </Row>
        <Row className='card-spacer'>
            <Col md={6} sm={12} xs={12}>
                <div className='d-flex flex-row max-height'>
                    <img className='block-disp align-self-center third-img auto-margin' src={ourMissionImg}/>
                </div>
            </Col>
            <Col md={6} sm={12} xs={12} className='column-container'>
                <div className='align-self-center'>
                    <div className='card-title mt-3 red-font-color label-align'>
                        Our Missions
                    </div>
                    <div className='content-label semi-black-font-color label-align align-self-center'>
                        To create fun and engaging events and activities that encourage and enhance human health and
                        wellbeing. To use technologies to bridge human health and wellbeing with organisational
                        behaviour
                        and performance.
                    </div>
                </div>
            </Col>
        </Row>
    </Col>
);

export default Description;
