import React from "react";
// The import below should be updated to match your Router component
import Routes from "./Routes";
import DynamicSitemap from "react-dynamic-sitemap";

export default function Sitemap(props) {
    return (
        <DynamicSitemap sitemapIndex={true} routes={Routes} prettify={true} {...props}/>
    );
}
