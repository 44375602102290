import React from "react";
import './AdvantagesOfCSR.css';
import {Col, Row} from "react-bootstrap";
import BulletList from "../../../../CommonComponent/BulletList/BulletList";

const AdvantagesOfCSR = () => (
    <Col md={{span: 10, offset: 1}} sm={12} xs={12} className='card-spacer'>
        <div className='card-title red-font-color label-align'>
            Advantages of organizing a CSR wellness event-with LIF:
        </div>
        <BulletList dotSize={8} dotColor={'#439D8E'} datas={['Give back and make a positive contribution to society','Effective driver of employee engagement','Promote health & wellbeing to your organisation','Raise awareness for a cause','Fun and innovative CSR program']}/>
    </Col>
);

export default AdvantagesOfCSR;
