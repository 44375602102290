import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import './BlogsCard.css';
import {isMobile} from 'react-device-detect';
import {Divider, Pagination} from "antd";
import searchBtn from '../../../../assets/search-btn.svg';
import BlogsListCard from "../BlogsListCard/BlogsListCard";
import arrowRight from '../../../../assets/right-arrow.svg';
import axiosInstance from "../../../CommonComponent/AxiosInstane";
import history from "../../../CommonComponent/history";
import {withRouter} from "react-router-dom";
import SearchBar from "../CommonComponent/SearchBar/SearchBar";
import CategorySideBar from "../CommonComponent/CategorySideBar/CategorySideBar";
import RecentPostSideBar from "../CommonComponent/RecentPostSideBar/RecentPostSideBar";
import * as qs from 'qs';

const BLOG_CATEGORY = {
    PHYSICAL_FITNESS: 1,
    FOOD_NUTRITION: 2,
    SLEEP_REST: 3,
    CORPORATE_WELLNESS: 4
}

class BlogsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blogData: [],
            filteredData: [],
            currentPageBlog: [],
            currentPage: 1,
            totalData: 1,
            limit: 10,
            searchBlogName: '',
            searchBlogCategory: null
        }
        this.onPageChange = this.onPageChange.bind(this);
        this.filterName = this.filterName.bind(this);
        this.filterCategory = this.filterCategory.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        let searchName = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).search;
        if (!searchName) searchName = "";
        let category = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).category;
        if (!category) category = "";
        axiosInstance.get(`blogs?page=${this.state.currentPage}&limit=${this.state.limit}&searchBy=lower(title)&searchValue=${searchName}&filter[0].filterBy=categoryid&filter[0].filterValue=${category}`).then(json => {
            let response = json.data;
            console.log("response", response);
            if (response.success) {
                const result = response.result;
                this.setState({totalData: result.totalRows});
                const currentPage = response.result.data;
                this.setState({currentPageBlog: currentPage});
            }
        });
    }

    onPageChange(page, pageSize) {
        if (pageSize) {
            this.setState({limit: pageSize, currentPage: page}, () => this.loadData());
        } else {
            this.setState({currentPage: page}, () => this.loadData());
        }
    };

    filterCategory(category) {
        this.setState({searchBlogCategory: category}, () => this.loadData());
    }

    filterName(searchName) {
        this.setState({searchBlogName: searchName}, () => this.loadData());
    }

    filterBlogs(category, searchName) {
        const filteredBlogs = this.state.blogData.filter(o => {
            const cat =
                category != null
                    ? o.CategoryID === category
                    : true;
            const name =
                searchName !== ''
                    ? o.Title.toLowerCase().indexOf(searchName.toLowerCase()) > -1
                    : true;
            return cat && name;
        });
        console.log('filtered blogs', filteredBlogs);
        return filteredBlogs;
    }

    redirectIntoBlog(blogData) {
        let titleUrl = blogData.Title.toLowerCase().replace(/ /g, "-");
        titleUrl = encodeURIComponent(titleUrl);
        const url = `/blogs/${titleUrl}`;
        this.props.history.push({
            pathname: url,
            state: {detail: blogData}
        })
    }


    blogLink(data) {
        var slug = data.Title.toLowerCase().replace(/ /g, "-");
        window.parent.location.href = "/blogs/" + slug;
    }

    render() {
        return (
            <Col md={11} className='auto-margin card-spacer'>
                <Row>
                    <Col md={9} sm={12} xs={12} className='content-padding blogs-container border-right'>
                        <div className='solution-title red-font-color label-align content-spacer'>LATEST BLOG</div>
                        <div className='column-container'>
                            <div className='content-spacer d-flex flex-column'>
                                <Row className='auto-margin'>
                                    {this.state.currentPageBlog.map((blog, index) => {
                                            return (
                                                <BlogsListCard key={index} description={blog.Description}
                                                               category={blog.Category.Name}
                                                               image={blog.ImageURL} title={blog.Title}
                                                               onReadMore={() => this.blogLink(blog)}/>
                                            )
                                        }
                                    )
                                    }
                                </Row>
                                <div className='pagination-container'>
                                    <Pagination pageSize={this.state.limit} current={this.state.currentPage}
                                                total={this.state.totalData}
                                                pageSizeOptions={["10", "20", "30"]}
                                                onChange={this.onPageChange}/>
                                </div>
                            </div>
                            <Divider type='vertical'/>
                        </div>
                    </Col>
                    <Col md={3} sm={12} xs={12} className='card-spacer'>
                        <SearchBar value={this.state.searchBlogName}
                                   searchCallbackOnChange={e => {
                                       history.push('/blogs?search=' + e.target.value);
                                       this.filterName(e.target.value);
                                   }
                                   }/>
                        <CategorySideBar categoryCallbackAction={cat => {
                            history.push('/blogs?category=' + cat);
                            this.filterCategory(cat);
                            window.scrollTo(0, 0);
                        }
                        }/>
                    </Col>
                </Row>
            </Col>
        )
    }
}

export default withRouter(BlogsCard);
