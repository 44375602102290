import React from "react";
import {Col, Row} from "react-bootstrap";
import './LifestyleTitle.css';
import lifestyleBg from '../../../../../assets/lifestyle-assesment-title-bg.png';

const LifestyleTitle = () => (
    <Col md={12} className='Lifestyle'>
        <Row>
            <Col md={{span: 5, offset: 1}} sm={12} xs={12} className='solution-title title-margin title-line-height grey-font-color'>
                Reach your <div className='large-page-title red-font-color'>health & performance</div> potential with science <div>- backed confidence</div>
            </Col>
            <Col md={6} sm={12} xs={12}>
                <img className='img-fluid float-right' src={lifestyleBg}/>
            </Col>
        </Row>
    </Col>
);

export default LifestyleTitle;
