import React from "react";
import './LPFooter.css';

const LPFooter = ({}) => {
    return (
        <div className='col-12 d-flex flex-row justify-content-center align-items-center' style={{backgroundColor: '#23282C', height: '45px'}}>
            <div className='footer-lbl lif-cocrol'>
                © LIF {new Date().getFullYear()} All rights reserved.
            </div>
        </div>
    )
}

export default LPFooter;
