import React from "react";
import {Col, Row} from "react-bootstrap";
import './WhatIsCWP.css';
import whatIsCwpImg from '../../../../../assets/what-is-cwp.svg';

const WhatIsCWP = () => (
    <Col md={{span: 11, offset: 1}} sm={10} xs={10} className='auto-margin card-spacer'>
        <Row>
            <Col md={3} sm={12} xs={12} className='auto-margin'>
                <img className='auto-margin block-disp what-is-cwp-img' src={whatIsCwpImg}/>
            </Col>
            <Col md={9} sm={12} xs={12} className='column-container mt-sm-3'>
                <div className='align-self-center'>
                    <div className='card-title label-align red-font-color'>WHAT IS LIF CORPORATE WELLNESS PROGRAM?</div>
                    <div className='content-label label-align semi-black-font-color'>Programs to encourage employees
                        toward active and healthy living in the workplace backed by state-of-the-art technology
                    </div>
                </div>
            </Col>
        </Row>
    </Col>
);


export default WhatIsCWP;
