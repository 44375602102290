import React from "react";
import dancingImage from '../../../../assets/lp/lp-dancing.svg';
import './DancingContent.css';
import {isMobile} from "react-device-detect";

const DancingContent = ({}) => {
    return (
        <div className='col-md-10 auto-margin d-flex flex-column align-items-center justify-content-center'>
            <img className='dancing-img' src={dancingImage} />
            <div className='mt-4 subtitle-emp-lbl text-center lif-tosca'>
                Build Healthy {isMobile && <br/>} and Happy Individuals
                <div className='lif-black'>in Your Workplace</div>
            </div>
        </div>
    )
}

export default DancingContent;
