import {Row} from "react-bootstrap";
import PropTypes from 'prop-types';
import {Divider} from "@material-ui/core";
import React from "react";
import './GeneralButton.css'

const GeneralButton = ({icon, divider, label, color, labelColor, onClickAction}) => (
    <div className='general-button' onClick={onClickAction} style={{backgroundColor: color}}>
        <Row className='max-height justify-content-between'>
            {icon && <img src={icon}/>}
            {divider && <Divider style={{marginLeft: '10px'}} orientation="vertical"/>
            }
            <div className='button-label' style={{color: labelColor}}>{label}</div>
        </Row>
    </div>
);

GeneralButton.propTypes = {
    icon: PropTypes.string,
    divider: PropTypes.bool,
    label: PropTypes.any.isRequired,
    color: PropTypes.string,
    labelColor: PropTypes.string,
    onClickAction: PropTypes.any
};

GeneralButton.defaultProps = {
    divider: false,
    color: '#DE1E30',
    labelColor: '#FFFFFF'
};

export default GeneralButton;
