import React from "react";
import './BlogsListCard.css';
import PropTypes from 'prop-types';
import {Divider} from "antd";
import blogsImg from '../../../../assets/blogs-img.png';
import ReactQuill from "react-quill";
import {Link} from "react-router-dom";

const imageStyle = (imgUrl, width) => (
    {
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        height: `${0.57*width}px`,
    }
);

class BlogsListCard extends React.Component{
    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.state = {
            containerWidth: 0,
        }
    }
    componentDidMount() {
        this.setState({containerWidth: this.container.current.offsetWidth})
    }

    render() {
        return(
            <div ref={this.container} className='BlogsListCard' style={this.props.width && {maxWidth : this.props.width+'%'}} onClick={this.props.onReadMore}>
                <div className='blog-img-container' style={imageStyle(this.props.image, this.state.containerWidth)}>
                </div>
                <div className='blog-content'>
                    {this.props.category !== '' &&
                    <div className='blog-category'>
                        {this.props.category}
                    </div>
                    }
                    <div className='blog-title-list-card'>
                        {this.props.title}
                    </div>
                </div>
            </div>
        )
    }
}

BlogsListCard.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    category: PropTypes.string,
    onReadMode: PropTypes.func,
    width: PropTypes.number
};

BlogsListCard.defaultProps = {
    category: '',
    width: null
};

export default BlogsListCard;
