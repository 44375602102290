import React from "react";
import './SolutionCard.css';
import '../../../../App.css';
import {Col, Row} from "react-bootstrap";
import learnMoreIcon from '../../../../assets/learnmore-icon.svg';
import lifestyleAssesment from '../../../../assets/lifestyle-assesment.jpg';
import corporateWellnessProgram from '../../../../assets/corporate-wellness-program-img.png';
import csrWellnessImg from '../../../../assets/csr-wellness-img.png';
import Style from 'style-it';
import history from "../../../CommonComponent/history";

const LearnMoreBtn = ({url}) => (
    <a href={url}>
        <div style={{cursor: 'pointer'}} className='col red-btn'>
            <Row>
                <div className='lbl-btn justify-content-center'>Learn more</div>
                <img src={learnMoreIcon}/>
            </Row>
        </div>
    </a>
);

const LearnMore = ({image, title, label, url}) => (
    <Style>
        {`
            .learn-more-container:hover{
                margin-top: -10px;
            }`
        }
        <div className='learn-more-container'>
            <Row>
                <Col md={12} sm={4}>
                    <img className='img-fluid solution-image' src={image}/>
                </Col>
                <Col md={12} sm={8} className='learn-more-word-container'>
                    <div className='content-title grey-font-color justify-content-center item-spacer center-label'>
                        {title}
                    </div>
                    <div
                        className='content-label semi-black-font-color justify-content-center item-spacer center-label'>
                        {label}
                    </div>
                    <LearnMoreBtn url={url}></LearnMoreBtn>
                </Col>
            </Row>
        </div>
    </Style>
);

const SolutionCard = () => (
    <Col md={10} className='justify-content-between auto-margin card-spacer'>
        <div className='card-title center-label red-font-color'>Our solutions for your people</div>
        <Row>
            <Col>
                <LearnMore url={'/corporatewellnessprogram'} image={corporateWellnessProgram} title={'Corporate Wellness Program'}
                           label={'Empower your people with engaging and fun wellness program experience'}></LearnMore>
            </Col>
            <Col>
                <LearnMore url={'/lifestyle'} image={lifestyleAssesment} title={'Lifestyle Assessment'}
                           label={'Reach your health and performance potential with science-backed confidence'}></LearnMore>
            </Col>
            <Col>
                <LearnMore url={'/csr'} image={csrWellnessImg} title={'CSR Wellness Events'}
                           label={'Get fit, challenge your people, raise awareness and funds for a great cause'}></LearnMore>
            </Col>
        </Row>
    </Col>
);

export default SolutionCard;
