import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./components/CommonComponent/Header/Header";
import Footer from "./components/CommonComponent/Footer/Footer";
import GuideDownload from "./components/HomePage/components/GuideDownload/GuideDownload";
import Routes from "./components/CommonComponent/Routes";
import waLogo from "./assets/wa-logo.png";
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-phone-input-2/lib/style.css'

class App extends React.Component {
    constructor() {
        super();
        this.state = {
            loaded: false,
        }
        this.handleLoad = this.handleLoad.bind(this);
        if (window.location.pathname !== "/lp" && window.location.pathname.indexOf("pages") === -1) {
            this.guideForm = React.createRef();
        }
    }

    componentDidMount() {
        window.addEventListener('load', this.handleLoad);
    }

    handleLoad() {
        if (window.location.pathname !== "/lp" && window.location.pathname.indexOf("pages") === -1) {
            this.guideForm.current.openForm();
        }
    }

    render() {
        return (
            <div className="App">
                {window.location.pathname !== "/lp" && window.location.pathname.indexOf("pages") === -1 ? (
                    <div>
                        <GuideDownload ref={this.guideForm}/>
                        <Header/>
                    </div>
                ) : null}
                <Routes/>
                {window.location.pathname !== "/lp" && window.location.pathname.indexOf("pages") === -1 ? (
                    <Footer/>
                ) : null}
                {window.location.pathname.indexOf("pages") === -1 ? (
                    <a href='https://api.whatsapp.com/send?phone=628999747479&text=Halo saya ingin bertanya tentang LIF' target='_blank'>
                        <img className='wa-logo' src={waLogo}/>
                    </a>) : null}
            </div>
        );
    }
}

export default App;
