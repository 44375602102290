import React from 'react';
import './WellnessProgress.css'
import {Col, Row} from "react-bootstrap";
import CaloriesLogo from '../../../../assets/calories.png';
import challengeLogo from '../../../../assets/challenge-logo.png';
import distanceLogo from '../../../../assets/distance-logo.png';
import stepsLogo from '../../../../assets/steps-logo.png';
import axiosInstance from "../../../CommonComponent/AxiosInstane";

function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
}

const Progress = ({image, count, label}) => (
    <div className='progress-container'>
        <img className='auto-margin' style={{height: '68px', display: 'block'}} src={image}/>
        <div className='content-title center-label' style={{color: '#DE1E30'}}>
            {numberWithCommas(count)}
        </div>
        <div className='progress-label center-label'>{label}</div>
    </div>
);

class WellnessProgress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: 10860731353,
            calories: 2965593406,
            distance: 8802546,
            stepsChallenge: 3082
        }
    }

    render() {
        return (
            <Col md={10} className='auto-margin'>
                <Row>
                    <Col md={3} sm={12}>
                        <Progress image={challengeLogo} label={'Steps Challenge Run'}
                                  count={this.state.stepsChallenge}/>
                    </Col>
                    <Col md={3} sm={12}>
                        <Progress image={stepsLogo} label={'Steps Walked'} count={this.state.steps}/>
                    </Col>
                    <Col md={3} sm={12}>
                        <Progress image={distanceLogo} label={'Total Distance Covered'}
                                  count={this.state.distance}/>
                    </Col>
                    <Col md={3} sm={12}>
                        <Progress image={CaloriesLogo} label={'Total Calories Burned'}
                                  count={this.state.calories}/>
                    </Col>
                </Row>
            </Col>
        );
    }
}

export default WellnessProgress;
