import React from 'react';
import './Footer.css';
import {Col, Row} from "react-bootstrap";
import logoLarge from '../../../assets/lif-logo-large.svg';
import igLogo from '../../../assets/ig-logo.svg';
import ytLogo from '../../../assets/yt-logo.svg';
import linkedinLogo from '../../../assets/linkedin-logo.svg';
import fbLogo from '../../../assets/fb-logo.svg';

const Footer = () => (
    <div className='footer'>
        <svg className='footer-wave' viewBox="0 0 1750 460" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M-1012.54 905.997C-345.523 1679.69 767.654 1865.74 1634.35 1348.43C1918.91 1178.66 2015.8 803.095 1850.89 509.638C1685.98 216.18 1321.54 115.707 1037.13 285.551C657.425 512.136 169.521 430.139 -123.06 90.7617C-416.374 -249.371 -437.501 -759.417 -173.174 -1122.15C24.2499 -1392.97 -28.8793 -1778.05 -291.66 -1981.77C-554.66 -2185.8 -927.698 -2131.73 -1125.19 -1860.76C-1728.64 -1033.12 -1681.24 130.492 -1012.54 905.997Z"
                fill="#F5F2EA"/>
        </svg>
        <div className='footer-data'>
            <Col md={8} xs={10} sm={10} id='footer-title' className='footer-upper-container auto-margin'>
                <Row className='justify-content-between'>
                    <Col md={4} sm={12} className='container'>
                        <Row className='justify-content-between'>
                            <Col md={12} sm={6} xs={6} className='auto-margin'>
                                <a href='/'>
                                    <img className='img-fluid auto-margin block-disp' src={logoLarge}/>
                                </a>
                            </Col>
                            <Col md={12} sm={5} xs={5} className='container auto-margin align-self-center'>
                                <div className='no-padding column-container'>
                                    <div className='column-container auto-margin'>
                                        <div>
                                            <a href='https://www.youtube.com/channel/UCCGQSgLrFQLZj-5J0qU_DrA'
                                               target='_blank'>
                                                <img className='img-fluid ml-md-2 mt-2' src={ytLogo}/>
                                            </a>
                                            <a href='https://www.linkedin.com/company/lif-indonesia' target='_blank'>
                                                <img className='img-fluid ml-md-2 mt-2' src={linkedinLogo}/>
                                            </a>
                                        </div>
                                        <div>
                                            <a href='https://facebook.com/lif.Indonesia' target='_blank'>
                                                <img className='img-fluid ml-md-2 mt-2' src={fbLogo}/>
                                            </a>
                                            <a href='https://www.instagram.com/lif.indonesia/' target='_blank'>
                                                <img className='img-fluid ml-md-2 mt-2' src={igLogo}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} sm={12} className='footer-upper-spacer container'>
                        <div className='footer-upper-title'>Company</div>
                        <div className='footer-upper-spacer'>
                            <a className='footer-upper-subtitle' onClick={() => window.parent.location.href ='https://lif.id/aboutlif'}>About LIF</a>
                            <a className='footer-upper-subtitle' onClick={() => window.parent.location.href ='https://lif.id/blogs'}>Healthy Living</a>
                            <a className='footer-upper-subtitle' onClick={() => window.parent.location.href ='https://lif.id/contactus'}>Contact Us</a>
                            <a className='footer-upper-subtitle' onClick={() => window.parent.location.href ='https://lif.id/contactus'}>Become a Partner</a>
                            <a className='footer-upper-subtitle' onClick={() => window.parent.location.href ='https://lif.id/corporate-wellness-guide'}>Resource</a>
                        </div>
                    </Col>
                    <Col md={4} sm={12} className='footer-upper-spacer container'>
                        <div className='footer-upper-title'>Our Solutions</div>
                        <div className='footer-upper-spacer'>
                            <a className='footer-upper-subtitle' onClick={() => window.parent.location.href ='https://lif.id/corporatewellnessprogram'}>LIF Corporate Wellness Program</a>
                            <a className='footer-upper-subtitle' onClick={() => window.parent.location.href ='https://lif.id/lifestyle'}>Lifestyle Assesment</a>
                            <a className='footer-upper-subtitle' onClick={() => window.parent.location.href ='https://lif.id/csr'}>Wellness Event</a>
                        </div>
                    </Col>
                </Row>
            </Col>
            <div className="footer-container">
                <Row className='justify-content-between'>
                    <div className='footer-label'>
                        © LIF {new Date().getFullYear()} All rights reserved.
                    </div>
                    <div className='footer-label'>
                        <a href='/termofservice'  className='footer-label' target='_blank'>Terms and Conditions</a> | <a  className='footer-label' href='/privacypolicy' target='_blank'>Privacy Policy</a>
                    </div>
                </Row>
            </div>
        </div>
    </div>
);

export default Footer;
