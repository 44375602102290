import React from "react";
import './FreeDownloadSideBar.css';
import GeneralButton from "../../../../CommonComponent/GeneralButton/GeneralButton";
import bookImg from "../../../../../assets/download-book.png";
import history from "../../../../CommonComponent/history";

const FreeDownloadSideBar = () =>(
    <div className='FreeDownload d-flex flex-row flex-wrap justify-content-around'>
        <div className='circle-free-download-bg content-spacer'>
            <img className='img-fluid auto-margin' src={bookImg}/>
        </div>
        <div className='auto-margin content-title corporate-container'>
            Corporate Wellness<br/>
            Programs Guidelines 2021
            <div className='content-label item-spacer'>
                Learn how to start a corporate wellness<br/>
                program that employees will want to join
            </div>
            <div className='item-spacer'>
                <GeneralButton label={'Free Download'} onClickAction={() => history.push('/corporate-wellness-guide')}/>
            </div>
        </div>
    </div>
)

export default FreeDownloadSideBar;
