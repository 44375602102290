import React from "react";
import {Col, Row} from "react-bootstrap";
import './CWPUpperContent.css';
import logo from '../../../../assets/lif-logo.svg';
import reportImg from '../../../../assets/lp/img-reportx2.png';
import ContactUs from "../../../CommonComponent/ContactUs/ContactUs";
import GetStartedButton from "../GetStarted/GetStarted";


const CWPUpperContent = ({}) =>{
    return(
        <Col md={10} sm={12} xs={12} className='auto-margin mt-md-4 mt-sm-2'>
            <div style={{paddingTop: '5%'}}>
                <img className='logo-image' onClick={() => window.parent.location.href ='https://lif.id'} src={logo} style={{cursor: 'pointer'}}/>
            </div>
            <Row className='mt-3 d-flex align-items-center label-align flex-md-row flex-sm-column-reverse flex-wrap'>
                <Col md={6} sm={12} xs={12}>
                    <div className='titleLbl lif-black'>
                        Corporate<br/>
                        Wellness<br/>
                        <span className='lif-tosca'>Solution</span>
                    </div>
                    <div className='subtitleLbl mt-3 label-align'>
                        Improve your employees wellbeing and engagement by doing healthy & fun activities together through technology - Anywhere
                    </div>
                    <div className='mt-4 d-flex justify-content-xs-center justify-content-md-start'>
                        <GetStartedButton/>
                    </div>
                </Col>
                <Col md={6} sm={12} xs={12}>
                    <img className='img-fluid' src={reportImg}/>
                </Col>
            </Row>
        </Col>
    )
}

export default CWPUpperContent;
