import arrowRight from "../../../../../assets/right-arrow.svg";
import React from "react";
import './CategorySideBar.css';
import {Col} from "react-bootstrap";
import PropTypes from "prop-types";
import SearchBar from "../SearchBar/SearchBar";

const BLOG_CATEGORY = {
    PHYSICAL_FITNESS: 1,
    FOOD_NUTRITION: 2,
    SLEEP_REST: 3,
    CORPORATE_WELLNESS: 4
}

const Category = ({categoryName, clickAction}) => (
    <div onClick={clickAction} className='column-container category-container'>
        <img src={arrowRight}/>
        <div className='category-lbl ml-3'>
            {categoryName}
        </div>
    </div>
)
class CategorySideBar extends React.Component{
    constructor(props) {
        super(props);
    }
    handleCategoryCallBack(category){
        this.props.categoryCallbackAction(category)
    }
    render() {
        return(
            <div>
                <div className='content-spacer content-title red-font-color ml-4'>
                    Categories
                </div>
                <div className='content-spacer'>
                    <Category clickAction={()=>this.handleCategoryCallBack(BLOG_CATEGORY.PHYSICAL_FITNESS)}
                              categoryName={'Physical fitness'}/>
                    <Category clickAction={()=>this.handleCategoryCallBack(BLOG_CATEGORY.FOOD_NUTRITION)}
                              categoryName={'Food and Nutrition'}/>
                    <Category clickAction={()=>this.handleCategoryCallBack(BLOG_CATEGORY.SLEEP_REST)}
                              categoryName={'Sleep and rest'}/>
                    <Category clickAction={()=>this.handleCategoryCallBack(BLOG_CATEGORY.CORPORATE_WELLNESS)}
                              categoryName={'Corporate Wellness'}/>
                </div>
            </div>
        )
    }
}

CategorySideBar.propTypes = {
    categoryCallbackAction: PropTypes.func
};
export default CategorySideBar;
