import React from "react";
import {Col} from "react-bootstrap";
import './ExampleCard.css';
import clsx from "clsx";
import {exampleCards} from "./ExampleCardConst";
import topBg from "../../../../assets/lp/top-circle.svg";
import bottomBg from "../../../../assets/lp/bottom-circle.svg";

const BubleImage = ({image}) =>{
    return(
        <div className='auto-margin'>
            <div className='rounded-bg'/>
            <img className='img-fluid position-relative auto-margin' src={image}/>
        </div>
    )
}

const LineImageCard = ({title, label, image, reverse}) => {
    return(
        <div className={clsx('d-flex mt-5 justify-contents-center flex-wrap align-items-center', !reverse ? 'flex-row' : 'flex-row-reverse')}>
            <Col md={5} sm={12} xs={12}>
                <BubleImage image={image}/>
            </Col>
            <Col md={7} sm={12} xs={12} className='label-align mt-4'>
                <div className='subtitle-emp-lbl lif-white'>
                    {title}
                </div>
                <div className='subtitleLbl lif-white mt-2'>
                    {label}
                </div>
            </Col>
        </div>
    )
}

const ExampleCard = ({}) =>{
    return(
        <div className='example-root'>
            <svg className='image' width="1920" height="316" viewBox="0 0 1920 316" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0C138.8 82.0621 288.755 147.36 447.733 192.795C955.939 339.246 1482.39 261.597 1920 10.2971V315.44H0V0Z" fill="#41907B"/>
            </svg>
            <div className='col-12 example-container'>
                <Col md={10} sm={12} xs={12} className='auto-margin d-flex flex-row flex-wrap'>
                    {exampleCards.map(value => <LineImageCard image={value.image} reverse={value.reverse}
                                                              label={value.label} title={value.title}/>)}
                </Col>
            </div>
            <svg className='image' width="1920" height="189" viewBox="0 0 1920 189" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1920 80.2689C1299.92 222.058 646.162 228.376 -0.000976562 80.252V0.638184H1920V80.2689Z" fill="#41907B"/>
            </svg>
        </div>
    )
}

export default ExampleCard;
