export const comments = [
    {
        comment: 'Sangat bersyukur sekali bisa bergabung dengan LIF karena banyak manfaat yang sudah saya dapatkan. LIF menyadarkan saya betapa pentingnya menjalani hidup sehat dan lebih bersemangat dalam menjalankan aktivitas sehari hari.',
        name: 'Riyawati Purwo',
        position: 'PARTICIPANTS',
        org: 'BAKTI PENDIDIKAN DJARUM FOUNDATION'
    },
    {
        comment: 'Banyak yang dulu tidak berminat untuk berolahraga, sekarang sangat bersemangat untuk mencoba berolahraga. Selain itu, program ini menunjang pengembangan karyawan di perusahaan, dan menjadi wadah untuk meningkatkan kekeluargaan karyawan.',
        name: 'Karina Sugianto',
        position: 'HR MANAGER',
        org: 'GALVA TECHNOLOGIES'
    },
    {
        comment: 'We\'ve been trying to create and look for a sustainable and measurable corporate wellness program that can be monitored easily, and finally after we partnered with LIF, we have the solution for it and it’s enriched our HR programs.',
        name: 'Rony Triawan',
        position: 'HR DIRECTOR',
        org: 'THE GAIA HOTEL BANDUNG'
    }
]
