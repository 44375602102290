import {Router, Route, Switch} from "react-router-dom";
import CorporateWellnessProgram from "../Solutions/CorporateWellnessProgram/CorporateWellnessProgram";
import LifeStyleAssesment from "../Solutions/LifestyleAssesment/LifestyleAssesment";
import CSR from "../Solutions/CSR/CSR";
import AboutLIF from "../AboutLIF/AboutLIF";
import Blogs from "../Blogs/Blogs";
import ContactPage from "../ContactPage/ContactPage";
import DownloadForm from "../DownloadForm/DownloadForm";
import ThankyouPage from "../ThankyouPage/ThankyouPage";
import PrivacyPolicy from "../TOS/PrivacyPolicy/PrivacyPolicy";
import TermOfService from "../TOS/TermOfService/TermOfService";
import HomePage from "../HomePage/HomePage";
import history from "./history";
import BlogPage from "../BlogPage/BlogPage";
import ScrollToTop from "./ScrollToTop";
import Sitemap from "./Sitemap";
import LandingPage from "../LandingPage/LandingPage";
import TermOfUse from "./TermOfUse/TermOfUse";
import Pricing from "../Pricing/Pricing";
import PrivacyPolicyApp from "./PrivacyPolicyApp/PrivacyPolicyApp";

const Routes = () =>
    (
        <Router history={history}>
            <ScrollToTop/>
            <Switch>
                <Route path="/aboutlif" component={AboutLIF}/>
                <Route path="/blogs/:blogTitle" component={BlogPage}/>
                <Route path="/blogs" component={Blogs}/>
                <Route path="/corporatewellnessprogram" component={CorporateWellnessProgram}/>
                <Route path="/lifestyle" component={LifeStyleAssesment}/>
                <Route path="/csr" component={CSR}/>
                <Route path="/contactus" component={ContactPage}/>
                <Route path="/corporate-wellness-guide" component={DownloadForm}/>
                <Route path="/thankyou" component={ThankyouPage}/>
                <Route path="/privacypolicy" component={PrivacyPolicy}/>
                <Route path="/termofservice" component={TermOfService}/>
                <Route path="/sitemap" component={Sitemap}/>
                <Route path="/lp" component={LandingPage}/>
                <Route path="/pricing" component={Pricing}/>
                <Route path="/pages/PrivacyPolicy.htm" component={PrivacyPolicyApp}/>
                <Route path="/pages/TermsOfUse.htm" component={TermOfUse}/>
                <Route path="/" component={HomePage}/>
            </Switch>
        </Router>
    );
export default Routes;
