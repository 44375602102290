import React from "react";
import './ContactPage.css';
import '../../App.css';
import {Button, Col, Form, Row} from "react-bootstrap";
import LifMaps from "../AboutLIF/components/LifMaps/LifMaps";
import GeneralButton from "../CommonComponent/GeneralButton/GeneralButton";
import axios from "axios";
import history from "../CommonComponent/history";
import axiosInstance from "../CommonComponent/AxiosInstane";
import PhoneInput from "react-phone-input-2";
import * as qs from 'qs';

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validatePhone(phone) {
    const re = /^[0-9]{10,15}$/;
    return re.test(phone);
}

class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requestDemo: false,
            becomingPartner: false,
            name: '',
            emailAddress: '',
            contactNumber: 0,
            jobTitle: '',
            company: '',
            message: '',
            errors: {}
        }
    }

    onSubmit() {
        if (this.handleValidation()) {
            const request = {
                WebsiteContact: {
                    Name: this.state.name,
                    Email: this.state.emailAddress,
                    Contact: this.state.contactNumber,
                    JobTitle: this.state.jobTitle,
                    Company: this.state.company,
                    Subject: this.state.requestDemo ? 'Demo Request, ' : '' + this.state.becomingPartner ? 'Become a Partner' :'',
                    Message: this.state.message
                }
            }
            console.log(request);

            axiosInstance.post('/contact', request).then(function (response) {
                console.log(response.data);
                    if(response.data.success){
                        history.push('/thankyou');
                    }
                }
            )
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        //Subject
        if (this.state.becomingPartner === false && this.state.requestDemo === false) {
            formIsValid = false;
            errors["subject"] = "Select at least one";
        }

        //Name
        if (this.state.name === '') {
            formIsValid = false;
            errors["name"] = "Cannot be empty";
        }

        //Email
        if (this.state.emailAddress === '') {
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        } else if (typeof this.state.emailAddress !== "undefined") {

            if (!validateEmail(this.state.emailAddress)) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
            var restrictDomain = ['gmail','yahoo','ymail','google'];
            if (new RegExp(restrictDomain.join("|")).test(this.state.emailAddress.toLowerCase())) {
                formIsValid = false;
                errors["email"] = "Please enter your business email address.";
            }
        }

        if (!validatePhone(this.state.contactNumber)) {
            formIsValid = false;
            errors["contactNumber"] = "Please input a valid contact number";
        }

        if (!this.state.jobTitle) {
            formIsValid = false;
            errors["jobTitle"] = "Cannot be empty";
        }
        if (!this.state.company) {
            formIsValid = false;
            errors["company"] = "Cannot be empty";
        }
        if (!this.state.message) {
            formIsValid = false;
            errors["message"] = "Cannot be empty";
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    componentDidMount() {
        let email = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).email;
        if (email) {
            email = decodeURIComponent(email);
            this.setState({emailAddress: email});
        }
    }

    render() {
        return (<div className='w-100 ContactPage'>
                <Col md={10} sm={12} xs={12} className='auto-margin card-spacer'>
                    <div className='large-page-title red-font-color center-label'>
                        Contact Us
                        <div className='auto-margin content-label semi-black-font-color center-label'>
                            Have a question about a product,
                            feedback, or business inquiry for LIF?
                        </div>
                    </div>
                    <div className='contact-form w-100 content-spacer'>
                        <div className='auto-margin content-title center-label grey-font-color'>
                            Drop us a message and
                            we’ll contact you soon!
                        </div>
                        <div className='content-spacer'>
                            <Form>
                                <Row>
                                    <Col md={6} sm={12} xs={12}>
                                        <div className='form-label mb-3 ml-md-3 label-align'>Subject</div>
                                        <Row className='ml-md-1'>
                                            <Col md={{span: 6, offset: 0}} sm={{span: 10, offset: 2}} xs={{span: 10, offset: 2}}>
                                                <Form.Group controlId="formBasicCheckbox">
                                                    <Form.Check className='form-label'
                                                                defaultChecked={this.state.becomingPartner}
                                                                onChange={e => this.setState({becomingPartner: !this.state.becomingPartner})}
                                                                type="checkbox" label="Become a Partner"/>
                                                </Form.Group>
                                            </Col>
                                            <Col md={{span: 6, offset: 0}} sm={{span: 10, offset: 2}} xs={{span: 10, offset: 2}}>
                                                <Form.Group controlId="formBasicCheckbox">
                                                    <Form.Check className='form-label'
                                                                defaultChecked={this.state.requestDemo}
                                                                onChange={e => this.setState({requestDemo: !this.state.requestDemo})}
                                                                type="checkbox" label="Demo Request"/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <div style={{
                                            color: "red",
                                            marginTop: '-15px'
                                        }}>{this.state.errors["subject"]}</div>
                                    </Col>
                                    <Col md={{span: 6}} sm={12} xs={12} className='mt-2'>
                                        <Form.Group>
                                            <div className='form-label mb-3 ml-md-3 label-align'>Name</div>
                                            <Form.Control className='rounded-corner general-form' value={this.state.name}
                                                          onChange={e => this.setState({name: e.target.value})}
                                                          type="text"/>
                                            <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} sm={12} xs={12}>
                                        <Form.Group controlId="formBasicEmail">
                                            <div className='form-label mb-3 ml-md-3 label-align'>Email Adress</div>
                                            <Form.Control className='rounded-corner general-form' value={this.state.emailAddress}
                                                          onChange={e => this.setState({emailAddress: e.target.value})}
                                                          type="email"/>
                                            <span style={{color: "red"}}>{this.state.errors["email"]}</span>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} sm={12} xs={12}>
                                        <Form.Group>
                                            <div className='form-label mb-3 ml-md-3 label-align'>Contact Number</div>
                                            <PhoneInput country={'id'} enableSearch={true} inputClass={'rounded-corner general-form'} inputStyle={{width: '100%'}} value={this.state.contactNumber} onChange={phone => this.setState({ contactNumber: phone })}/>
                                            <span style={{color: "red"}}>{this.state.errors["contactNumber"]}</span>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} sm={12} xs={12}>
                                        <Form.Group>
                                            <div className='form-label mb-3 ml-md-3 label-align'>Job Title</div>
                                            <Form.Control className='rounded-corner general-form' value={this.state.jobTitle}
                                                          onChange={e => this.setState({jobTitle: e.target.value})}
                                                          type="text"/>
                                            <span style={{color: "red"}}>{this.state.errors["jobTitle"]}</span>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} sm={12} xs={12}>
                                        <Form.Group>
                                            <div className='form-label mb-3 ml-md-3 label-align'>Company</div>
                                            <Form.Control className='rounded-corner general-form' value={this.state.company}
                                                          onChange={e => this.setState({company: e.target.value})}
                                                          type="text"/>
                                            <span style={{color: "red"}}>{this.state.errors["company"]}</span>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} sm={12} xs={12}>
                                        <Form.Group>
                                            <div className='center-label form-label semi-black-font-color mb-3'>Message
                                            </div>
                                            <Form.Control className='rounded-corner' value={this.state.message}
                                                          onChange={e => this.setState({message: e.target.value})}
                                                          as="textarea" rows={3}/>
                                            <div style={{color: "red"}}>{this.state.errors["message"]}</div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className='column-container content-spacer'>
                                    <div className='auto-margin'>
                                        <GeneralButton onClickAction={() => this.onSubmit()} label={'Submit'}/>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
                <Row>
                    <LifMaps/>
                </Row>
            </div>
        );
    }
}

export default ContactPage;
