import React from "react";
import './WhatIsLifestyle.css';
import {Col, Row} from "react-bootstrap";
import ContactUs from "../../../../CommonComponent/ContactUs/ContactUs";

const WhatIsLifestyle = () => (
    <Col md={10} sm={12} xs={12} className='card-spacer auto-margin'>
        <Row>
            <Col md={6} sm={12} xs={12}>
                <div className='card-title label-align red-font-color'>
                    WHAT IS LIFESTYLE ASSESMENT?
                </div>
                <div className='content-label label-align black-font-color'>
                    We’ve partnered with Firstbeat Technologies and Inbody to offer a 3-day program that uses a unique
                    heart rate variability analysis to gather data about your work, leisure and sleep, validated insight
                    of your health and performance.
                </div>
            </Col>
            <Col md={{span: 2, offset: 4}} sm={12} xs={12} className='align-self-center'>
                <div className='auto-margin' style={{width: 'fit-content'}}>
                    <ContactUs/>
                </div>
            </Col>
        </Row>
    </Col>
);
export default WhatIsLifestyle;
