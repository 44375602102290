import React from 'react';
import {Col, Nav, Row, Tab} from "react-bootstrap";
import Absenteeism from '../../../../assets/Absenteeism.jpg';
import TurnOver from '../../../../assets/TurnOver.png';
import Engagement from '../../../../assets/Engagement-2.png';
import HealthcareCost from '../../../../assets/HealthcareCost.png';
import './WorkplaceChallenge.css';
import '../../../../App.css';

const TabContent = ({word, image, copy}) => (
    <Col className='content-spacer'>
        <Row>
            <Col md={6} sm={12} className='tab-label content-padding label-align'>
                {word}
                <div className='tab-label-copy'>{copy}</div>
            </Col>
            <Col md={6} sm={12} className='content-padding'>
                <img className='tab-image ' src={image}/>
            </Col>
        </Row>
    </Col>
    );

const WorkplaceChallenge = () => (
    <Col md={10} className='auto-margin card-spacer'>
        <div className='card-title red-font-color center-label'>Workplace Challenges</div>
        <div className='content-spacer'>
            <Tab.Container id="left-tabs-example" defaultActiveKey="Absenteeism">
                <Row>
                    <Col className='tab-container justify-content-between auto-margin'>
                        <Nav variant="tabs" className='justify-content-between d-flex'>
                            <Nav.Item as={Col} md={3} sm={12} xs={12}>
                                <Nav.Link eventKey="Absenteeism">Absenteeism</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as={Col} md={3} sm={12} xs={12}>
                                <Nav.Link eventKey="TurnOver">Turnover</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as={Col} md={3} sm={12} xs={12}>
                                <Nav.Link eventKey="Engagement">Engagement</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as={Col} md={3} sm={12} xs={12}>
                                <Nav.Link eventKey="HealthcareCosts">Healthcare Costs</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='auto-margin'>
                        <Tab.Content>
                            <Tab.Pane eventKey="Absenteeism">
                                <div className='tab-data'>
                                    <TabContent copy={'*WHO, 2017'} image={Absenteeism}
                                                word={'Modern workplace often contributed to health risk: Physical inactivity, workplace stress, and negative work atmosphere that leads to absenteeism and decreasing productivity. Research has demonstrated that workplace health initiatives can help reduce sick leave absenteeism by 27%'}></TabContent>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="TurnOver">
                                <div className='tab-data'>
                                    <TabContent image={TurnOver} copy={'*Zenefits, 2017'}
                                                word={'According to new research of more than 600 businesses with 50-500 employees, 63.3% of companies say retaining employees is actually harder than hiring them. For each employee who leaves, the company owes recruitment, training costs and delays customer’s project or services. Investing in wellness program will attract top talents and retain company’s best talant'}></TabContent>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Engagement">
                                <div className='tab-data'>
                                    <TabContent image={Engagement} copy={'*Gallup, 2017'}
                                                word={'According to research only 15 percent of employees are engaged in the workplace. Pay attention to the employee disengagement phenomenon. It will cost you more than you think: Unproductive, less efficient, poor relationship and lack of leadership. A positive work culture will increase employee engagement'}></TabContent>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="HealthcareCosts">
                                <div className='tab-data'>
                                    <TabContent image={HealthcareCost} copy={'*CDC, 2015'}
                                                word={'An investment in employee health lowers health care costs and insurance claims. A systematic review of 56 published studies of worksite health programs showed that well-implemented workplace health programs can lead to 25% savings on health care costs.'}></TabContent>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    </Col>
);

export default WorkplaceChallenge;
