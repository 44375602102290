import React from "react";
import CWPUpperContent from "./components/CWPUpperContent/CWPUpperContent";
import TrustedBy from "./components/TrustedBy/TrustedBy";
import TechCard from "./components/TechCard/TechCard";
import DancingContent from "./components/DancingContent/DancingContent";
import ExampleCard from "./components/ExampleCard/ExampleCard";
import CommentsCard from "./components/CommentsCard/CommentsCard";
import BoostEmployee from "./components/BoostEmployee/BoostEmployee";
import LPFooter from "./components/LPFooter/LPFooter";

const LandingPage = ({}) => {
    return (
        <div style={{backgroundColor: '#F9F7F2', marginTop: '-4%'}}>
            <CWPUpperContent/>
            <TrustedBy/>
            <div className='mt-5'>
                <TechCard/>
            </div>
            <div className='mt-5'>
                <DancingContent/>
            </div>
            <ExampleCard/>
            <CommentsCard/>
            <BoostEmployee/>
            <LPFooter/>
        </div>
    )
}
export default LandingPage;
