import React from "react";
import './GuideDownload.css';
import '../../../../App.css';
import {Col, Row} from "react-bootstrap";
import closeButton from '../../../../assets/close-button.svg';
import GeneralButton from "../../../CommonComponent/GeneralButton/GeneralButton";
import history from "../../../CommonComponent/history";
import {render} from "@testing-library/react";

class GuideDownload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isClose: true
        }
        this.closeForm = this.closeForm.bind(this)
    }

    closeForm() {
        this.setState({isClose: true})
    }
    openForm() {
        this.setState({isClose: false})
    }

    render() {
        return (
            <Col>
                <Row className={this.state.isClose ? 'magnet-container hide-opacity' : 'magnet-container show-opacity'}>
                    <Col md={{span: 7, offset: 1}} sm={12} className='column-container'>
                        <div className='align-self-center content-label label-align'>
                            Essential guide to corporate wellness program. <span className='content-label' style={{'fontWeight': '800'}}><b>Download our free guide!</b></span>
                        </div>
                    </Col>
                    <Col md={4} sm={9} className='column-container position-relative'>
                        <div className='align-self-center justify-content-center auto-margin'>
                            <GeneralButton onClickAction={() => {
                                this.closeForm();
                                window.parent.location.href ='https://lif.id/corporate-wellness-guide';
                            }
                            } label='Download Now'/>
                        </div>
                        <div className='align-self-center justify-content-center auto-margin position-absolute mt-2' style={{'right': '0'}}>
                            <img style={{cursor: 'pointer', width: '38px'}} onClick={() => this.closeForm()}
                                 src={closeButton}/>
                        </div>
                    </Col>
                </Row>
            </Col>
        )
    }
};

export default GuideDownload;
