import activityTracker from '../../../../assets/lp/activity-tracker.svg';
import healthEducation from '../../../../assets/lp/health-education.svg';
import events from '../../../../assets/lp/events.svg';
import pointsAndReward from '../../../../assets/lp/points-rewards.svg';
import healthRisk from '../../../../assets/lp/health-risk.svg';
import fitnessChallenge from '../../../../assets/lp/fitness.svg';
import programManagement from '../../../../assets/lp/program-management.svg';
import report from '../../../../assets/lp/comprehensive-report.svg';

export const frameworks = [
    {
        image: activityTracker,
        tile: 'Apps & Activity Tracker',
        lbl: 'Our apps and tracker are versatile tools that will keep track of your employee\'s wellness activities.',
    },
    {
        image: healthEducation,
        tile: 'Health Education',
        lbl: 'Health education raises employees\' knowledge and motivation about wellness and how to maintain a healthy lifestyle through various media.',
    },
    {
        image: events,
        tile: 'Events',
        lbl: 'Our team of professionals are ready to create your wellness event more fun and memorable. You name it, we create it!',
    },
    {
        image: pointsAndReward,
        tile: 'LIF Points & Rewards',
        lbl: 'LIF rewards are effective tools to initiate and sustain participation. This is our ultimate spearhead to elevate the good culture at your workplace.',
    },
    {
        image: healthRisk,
        tile: 'Health Risk Assessment',
        lbl: 'We make our best effort to identify your employee\'s health risk by performing body composition measurement and questionnaire.',
    },
    {
        image: fitnessChallenge,
        tile: 'Fitness Challenge',
        lbl: 'We have measurable individual and team challenges to keep your employees active and connected with their peers.',
    },
    {
        image: programManagement,
        tile: 'Program Management',
        lbl: 'Let our team of wellness professionals help your employees build trust and confidence to achieve a healthy lifestyle and happiness.',
    },
    {
        image: report,
        tile: 'Comprehensive Report',
        lbl: 'Our reports are designed with you as the center. We provide a comprehensive and tailorable report right to your company’s doorstep.',
    },
];
