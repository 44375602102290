import React from "react";
import './GaleryCard.css';
import {Col, Row} from "react-bootstrap";
import childrenImg from '../../../../../assets/children-bg.png';
import bantengField from '../../../../../assets/banteng-field.png';
import bikeGroupImg from '../../../../../assets/group-bike.png';
import linaImg from '../../../../../assets/lina-lif.png';
import areWeRunningImg from '../../../../../assets/are-we-running-today.png';
import runningSrbyImg from '../../../../../assets/running-surabaya.png';
import andrianImg from '../../../../../assets/andrian.png';

const GaleryCard = () => (
    <Col md={10} className='auto-margin card-spacer'>
        <Row className='children-container'>
            <Col>
            <img className='image' src={childrenImg}/>
            <div className='small-steps-container center-label auto-margin large-page-title'>
                SMALL STEPS MAKE <br/> BIG CHANGES
            </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <img className='img-fluid img-margin' src={bantengField}/>
            </Col>
            <Col>
                <img  className='img-fluid img-margin' src={bikeGroupImg}/>
                <img  className='img-fluid img-margin' src={linaImg}/>
            </Col>
        </Row>
        <Row>
            <Col>
                <img className='img-fluid img-margin' src={areWeRunningImg}/>
            </Col>
            <Col>
                <img className='img-fluid img-margin' src={runningSrbyImg}/>
            </Col>
            <Col>
                <img className='img-fluid img-margin' src={andrianImg}/>
            </Col>
        </Row>
    </Col>
);

export default GaleryCard;
