import searchBtn from "../../../../../assets/search-btn.svg";
import React from "react";
import PropTypes from "prop-types";
import './SearchBar.css';

const SearchBar = ({value, searchCallbackOnChange, onSearchClick}) => (
    <div className='search-bar column-container'>
        <input value={value} onChange={searchCallbackOnChange} type='text' className='search-input'
               placeholder='Search'/>
        <img className='cursor-pointer' onClick={onSearchClick} src={searchBtn}/>
    </div>
)

SearchBar.propTypes = {
    value: PropTypes.string,
    searchCallbackOnChange: PropTypes.func,
    onSearchClick: PropTypes.func
};
SearchBar.defaultProps = {
    onSearchClick: null,
};

export default SearchBar;
