import React from "react";
import './WhatIsCSR.css';
import {Col, Row} from "react-bootstrap";
import ContactUs from "../../../../CommonComponent/ContactUs/ContactUs";

const WhatIsCSR = () => (
    <Col md={10} sm={12} xs={12} className='content-spacer auto-margin'>
        <Row>
            <Col md={6} sm={12} xs={12}>
                <div className='card-title label-align red-font-color'>
                    WHAT IS CSR wellness event?
                </div>
                <div className='content-label label-align black-font-color'>
                    Give back to the community and promote health at the same time with charitable fitness events. Our
                    team of professionals are ready to create your wellness event more fun and impactful. You name it,
                    we create it!
                </div>
            </Col>
            <Col md={{span: 2, offset: 4}} sm={12} xs={12} className='align-self-center'>
                <div className='auto-margin' style={{width: 'fit-content'}}>
                    <ContactUs/>
                </div>
            </Col>
        </Row>
    </Col>
);

export default WhatIsCSR;
