import React from "react";
import './ThankyouPage.css';
import {Col, Row} from "react-bootstrap";
import {render} from "@testing-library/react";
import axiosInstance from "../CommonComponent/AxiosInstane";
import BlogsListCard from "../Blogs/components/BlogsListCard/BlogsListCard";
import {isMobile} from 'react-device-detect';
import RandomBlogList from "../Blogs/components/CommonComponent/RandomBlogList/RandomBlogList";
import ytGrey from '../../assets/yt-grey.svg';
import linkedinGrey from '../../assets/linkedin-grey.svg';
import fbGrey from '../../assets/fb-grey.svg';
import igGrey from '../../assets/ig-grey.svg';

class ThankyouPage extends React.Component {
    constructor() {
        super();
        this.state = {
            blogData: []
        };
    }

    render() {
        return (
            <Col className='ThankyouPage auto-margin'>
                <div className='large-page-title red-font-color center-label'>
                    Thank you for getting in touch!
                </div>
                <div className='well-do-lbl grey-font-color mt-1 center-label auto-margin'>
                    <div>We’ll do our best to help you stay healthy and happy</div>
                    <div>Start by checking out some of our healthy living posts</div>
                </div>
                <div className='auto-margin card-spacer' style={{width: 'fit-content'}}>
                    <RandomBlogList/>
                </div>
                <div className='card-spacer auto-margin d-flex flex-row' style={{width: 'fit-content'}}>
                    <a href='https://www.youtube.com/channel/UCCGQSgLrFQLZj-5J0qU_DrA'
                       target='_blank'>
                        <img src={ytGrey} className='social-ty-img'/>
                    </a>
                    <a href='https://www.linkedin.com/company/lif-indonesia' target='_blank'>
                        <img src={linkedinGrey} className='social-ty-img'/>
                    </a>
                    <a href='https://facebook.com/lif.Indonesia' target='_blank'>
                        <img src={fbGrey} className='social-ty-img'/>
                    </a>
                    <a href='https://www.instagram.com/lif.indonesia/' target='_blank'>
                        <img src={igGrey} className='social-ty-img'/>
                    </a>
                </div>
            </Col>
        )
    }
}

export default ThankyouPage;
