import React, {useEffect} from "react";
import GeneralButton from "../../../CommonComponent/GeneralButton/GeneralButton";

const GetStartedButton = ({emailTo}) =>{
    let url = 'https://lif.id/contactus'
    useEffect(() => {
        if(emailTo){
            url = url + '?email='+encodeURIComponent(emailTo);
        }
    }, [emailTo]);
    return(
        <GeneralButton color={'#D31E2E'} label={'Get Started'} onClickAction={() => window.parent.location.href = url}/>
    )
}

export default GetStartedButton;
