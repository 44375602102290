import React, {Component, useState} from "react";
import './TechCard.css';
import {Col} from "react-bootstrap";
import {frameworks}  from './TechCardConst';

const Framework = ({title, image, label}) => {
    const [hovered, setHovered] = useState(false);
    const toggleHover = () => {
        setHovered(!hovered);
    }
    return (
        <div className='tech-container' onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
            <Col className='tech-content'>
                <img className='tech-logo auto-margin' src={image}/>
                <div className='title-lbl text-center'>{title}</div>
            </Col>
            {hovered && <div className='tech-content-container'>
                <Col className='align-self-center'>
                    <div className='lbl-word center-label' style={{color: 'white'}}>
                        {label}
                    </div>
                </Col>
            </div>
            }
        </div>
    );
};


const TechCard = ({}) =>{
    return(
        <Col md={10} sm={12} xs={12} className='auto-margin d-flex flex-md-row flex-column-xs-reverse flex-wrap justify-content-center align-items-center'>
            <Col md={6} sm={12} xs={12} className='auto-margin mt-3 d-flex flex-row flex-wrap justify-content-center '>
                {frameworks.map(value => <Framework title={value.tile} label={value.lbl} image={value.image}/>)}
            </Col>
            <Col md={6} sm={12} xs={12} className='label-align'>
                    <div className='titleLbl lif-green'>
                        TECH-BASED
                    </div>
                    <div className='subtitle-emp-lbl lif-black'>
                        Employee Wellness Program
                    </div>
                    <div className='subtitleLbl mt-3'>
                        The first end-to-end wellness program in Indonesia to encourage employees toward active and
                        healthy living in the workplace through technology
                    </div>
            </Col>
        </Col>
    )
}

export default TechCard;
