import React from "react";
import {Col, Row} from "react-bootstrap";
import './LifDifferentCard.css';
import techExperts from '../../../../../assets/tech-expertise-img.png';
import engagingAndFun from '../../../../../assets/engaging-fun-img.png';
import goalFocused from '../../../../../assets/goal-focused-img.png';

const DifferentCard = ({image, title, word, dotColor}) => (
    <Row className='different-card-container'>
        <Col md={6} sm={12} xs={12}>
            <img src={image} className='auto-margin lif-different-logo'/>
        </Col>
        <Col className='align-self-center' md={6} sm={12} xs={12}>
            <div className='word-container'>
                <div>
                    <div className='content-title label-align grey-font-color'>{title}</div>
                    <div className='content-label label-align black-font-color'>{word}</div>
                </div>
            </div>
        </Col>
    </Row>
);

const LifDifferentCard = () => (
    <Col md={10} xs={12} sm={12} className='card-spacer auto-margin'>
        <Row>
            <Col md={12} xs={10} sm={10} className='card-title auto-margin center-label' style={{color: '#DE1E30'}}>WHAT MAKES LIF WELLNESS
                PROGRAMS <span style={{color: '#439D8E'}}>DIFFERENT?</span></Col>
        </Row>
        <div className='card-spacer'>
            <DifferentCard image={techExperts} dotColor={'#EEB61B'} title={'Technology Expertise'}
                           word={'The core of our company is technology. We know digitalization is the future and we bring our technology to transform the industry.'}/>
            <DifferentCard image={engagingAndFun} dotColor={'#EEB61B'} title={'Engaging and Fun'}
                           word={'Wellness is dynamic and it extends beyond just physical health. We believe that an healthy lifestyle can be achieved in a fun way.'}/>
            <DifferentCard image={goalFocused} dotColor={'#EEB61B'} title={'Goal-Focused'}
                           word={'We are passionate geeks who know that it takes more than just mere conversations to thrive in your wellness journey. We are very goal-focused and placing our humanness at the center of the process.'}/>
        </div>
    </Col>
);

export default LifDifferentCard;
