module.exports = `<!DOCTYPE html>

<html xmlns:v="urn:schemas-microsoft-com:vml"
      xmlns:o="urn:schemas-microsoft-com:office:office"
      xmlns:w="urn:schemas-microsoft-com:office:word"
      xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
      xmlns="http://www.w3.org/TR/REC-html40">

<head>
    <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
        <meta name=ProgId content=Word.Document>
            <meta name=Generator content="Microsoft Word 15">
                <meta name=Originator content="Microsoft Word 15">
                    <link rel=File-List href="TermsOfUse_files/filelist.xml">
                        <!--[if gte mso 9]><xml>
                         <o:DocumentProperties>
                          <o:Author>lif.id</o:Author>
                          <o:LastAuthor>Septo Septo</o:LastAuthor>
                          <o:Revision>2</o:Revision>
                          <o:TotalTime>14</o:TotalTime>
                          <o:Created>2019-09-25T10:08:00Z</o:Created>
                          <o:LastSaved>2019-09-25T10:08:00Z</o:LastSaved>
                          <o:Pages>2</o:Pages>
                          <o:Words>728</o:Words>
                          <o:Characters>4154</o:Characters>
                          <o:Lines>34</o:Lines>
                          <o:Paragraphs>9</o:Paragraphs>
                          <o:CharactersWithSpaces>4873</o:CharactersWithSpaces>
                          <o:Version>16.00</o:Version>
                         </o:DocumentProperties>
                         <o:OfficeDocumentSettings>
                          <o:AllowPNG/>
                         </o:OfficeDocumentSettings>
                        </xml><![endif]-->
                        <link rel=themeData href="TermsOfUse_files/themedata.thmx">
                            <link rel=colorSchemeMapping href="TermsOfUse_files/colorschememapping.xml">
                                <!--[if gte mso 9]><xml>
                                 <w:WordDocument>
                                  <w:SpellingState>Clean</w:SpellingState>
                                  <w:GrammarState>Clean</w:GrammarState>
                                  <w:TrackMoves>false</w:TrackMoves>
                                  <w:TrackFormatting/>
                                  <w:PunctuationKerning/>
                                  <w:ValidateAgainstSchemas/>
                                  <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
                                  <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
                                  <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
                                  <w:DoNotPromoteQF/>
                                  <w:LidThemeOther>EN-US</w:LidThemeOther>
                                  <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
                                  <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
                                  <w:Compatibility>
                                   <w:BreakWrappedTables/>
                                   <w:SnapToGridInCell/>
                                   <w:WrapTextWithPunct/>
                                   <w:UseAsianBreakRules/>
                                   <w:DontGrowAutofit/>
                                   <w:SplitPgBreakAndParaMark/>
                                   <w:EnableOpenTypeKerning/>
                                   <w:DontFlipMirrorIndents/>
                                   <w:OverrideTableStyleHps/>
                                  </w:Compatibility>
                                  <m:mathPr>
                                   <m:mathFont m:val="Cambria Math"/>
                                   <m:brkBin m:val="before"/>
                                   <m:brkBinSub m:val="&#45;-"/>
                                   <m:smallFrac m:val="off"/>
                                   <m:dispDef/>
                                   <m:lMargin m:val="0"/>
                                   <m:rMargin m:val="0"/>
                                   <m:defJc m:val="centerGroup"/>
                                   <m:wrapIndent m:val="1440"/>
                                   <m:intLim m:val="subSup"/>
                                   <m:naryLim m:val="undOvr"/>
                                  </m:mathPr></w:WordDocument>
                                </xml><![endif]--><!--[if gte mso 9]><xml>
 <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
  DefSemiHidden="false" DefQFormat="false" DefPriority="99"
  LatentStyleCount="377">
  <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
  <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 9"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 1"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 2"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 3"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 4"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 5"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 6"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 7"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 8"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="header"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footer"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index heading"/>
  <w:LsdException Locked="false" Priority="35" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="caption"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of figures"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope return"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="line number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="page number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of authorities"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="macro"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="toa heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 5"/>
  <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Closing"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Signature"/>
  <w:LsdException Locked="false" Priority="1" SemiHidden="true"
   UnhideWhenUsed="true" Name="Default Paragraph Font"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Message Header"/>
  <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Salutation"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Date"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Note Heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Block Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="FollowedHyperlink"/>
  <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
  <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Document Map"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Plain Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="E-mail Signature"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Top of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Bottom of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal (Web)"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Acronym"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Cite"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Code"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Definition"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Keyboard"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Preformatted"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Sample"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Typewriter"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Variable"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Table"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation subject"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="No List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Contemporary"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Elegant"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Professional"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Balloon Text"/>
  <w:LsdException Locked="false" Priority="39" Name="Table Grid"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Theme"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
  <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
  <w:LsdException Locked="false" Priority="34" QFormat="true"
   Name="List Paragraph"/>
  <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
  <w:LsdException Locked="false" Priority="30" QFormat="true"
   Name="Intense Quote"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="19" QFormat="true"
   Name="Subtle Emphasis"/>
  <w:LsdException Locked="false" Priority="21" QFormat="true"
   Name="Intense Emphasis"/>
  <w:LsdException Locked="false" Priority="31" QFormat="true"
   Name="Subtle Reference"/>
  <w:LsdException Locked="false" Priority="32" QFormat="true"
   Name="Intense Reference"/>
  <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
  <w:LsdException Locked="false" Priority="37" SemiHidden="true"
   UnhideWhenUsed="true" Name="Bibliography"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
  <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
  <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
  <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
  <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
  <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
  <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
  <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hashtag"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Unresolved Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Link"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Link Error"/>
 </w:LatentStyles>
</xml><![endif]-->
                                <style>
                                    <!--
                                     /* Font Definitions */
                                     @font-face
                                        {font-family:Helvetica;
                                        panose-1:2 11 6 4 2 2 2 2 2 4;
                                        mso-font-charset:0;
                                        mso-generic-font-family:swiss;
                                        mso-font-pitch:variable;
                                        mso-font-signature:-536858881 -1073711013 9 0 511 0;}
                                    @font-face
                                        {font-family:Wingdings;
                                        panose-1:5 0 0 0 0 0 0 0 0 0;
                                        mso-font-charset:2;
                                        mso-generic-font-family:auto;
                                        mso-font-pitch:variable;
                                        mso-font-signature:0 268435456 0 0 -2147483648 0;}
                                    @font-face
                                        {font-family:"Cambria Math";
                                        panose-1:2 4 5 3 5 4 6 3 2 4;
                                        mso-font-charset:0;
                                        mso-generic-font-family:roman;
                                        mso-font-pitch:variable;
                                        mso-font-signature:-536869121 1107305727 33554432 0 415 0;}
                                     /* Style Definitions */
                                     p.MsoNormal, li.MsoNormal, div.MsoNormal
                                        {mso-style-unhide:no;
                                        mso-style-qformat:yes;
                                        mso-style-parent:"";
                                        margin-top:0in;
                                        margin-right:0in;
                                        margin-bottom:8.0pt;
                                        margin-left:0in;
                                        line-height:107%;
                                        mso-pagination:widow-orphan;
                                        font-size:11.0pt;
                                        font-family:"Calibri",sans-serif;
                                        mso-ascii-font-family:Calibri;
                                        mso-ascii-theme-font:minor-latin;
                                        mso-fareast-font-family:Calibri;
                                        mso-fareast-theme-font:minor-latin;
                                        mso-hansi-font-family:Calibri;
                                        mso-hansi-theme-font:minor-latin;
                                        mso-bidi-font-family:"Times New Roman";
                                        mso-bidi-theme-font:minor-bidi;}
                                    h2
                                        {mso-style-priority:9;
                                        mso-style-unhide:no;
                                        mso-style-qformat:yes;
                                        mso-style-link:"Heading 2 Char";
                                        mso-margin-top-alt:auto;
                                        margin-right:0in;
                                        mso-margin-bottom-alt:auto;
                                        margin-left:0in;
                                        mso-pagination:widow-orphan;
                                        mso-outline-level:2;
                                        font-size:18.0pt;
                                        font-family:"Times New Roman",serif;
                                        mso-fareast-font-family:"Times New Roman";
                                        font-weight:bold;}
                                    a:link, span.MsoHyperlink
                                        {mso-style-noshow:yes;
                                        mso-style-priority:99;
                                        color:blue;
                                        text-decoration:underline;
                                        text-underline:single;}
                                    a:visited, span.MsoHyperlinkFollowed
                                        {mso-style-noshow:yes;
                                        mso-style-priority:99;
                                        color:#954F72;
                                        mso-themecolor:followedhyperlink;
                                        text-decoration:underline;
                                        text-underline:single;}
                                    p
                                        {mso-style-noshow:yes;
                                        mso-style-priority:99;
                                        mso-margin-top-alt:auto;
                                        margin-right:0in;
                                        mso-margin-bottom-alt:auto;
                                        margin-left:0in;
                                        mso-pagination:widow-orphan;
                                        font-size:12.0pt;
                                        font-family:"Times New Roman",serif;
                                        mso-fareast-font-family:"Times New Roman";}
                                    span.Heading2Char
                                        {mso-style-name:"Heading 2 Char";
                                        mso-style-priority:9;
                                        mso-style-unhide:no;
                                        mso-style-locked:yes;
                                        mso-style-link:"Heading 2";
                                        mso-ansi-font-size:18.0pt;
                                        mso-bidi-font-size:18.0pt;
                                        font-family:"Times New Roman",serif;
                                        mso-ascii-font-family:"Times New Roman";
                                        mso-fareast-font-family:"Times New Roman";
                                        mso-hansi-font-family:"Times New Roman";
                                        mso-bidi-font-family:"Times New Roman";
                                        font-weight:bold;}
                                    span.GramE
                                        {mso-style-name:"";
                                        mso-gram-e:yes;}
                                    .MsoChpDefault
                                        {mso-style-type:export-only;
                                        mso-default-props:yes;
                                        font-family:"Calibri",sans-serif;
                                        mso-ascii-font-family:Calibri;
                                        mso-ascii-theme-font:minor-latin;
                                        mso-fareast-font-family:Calibri;
                                        mso-fareast-theme-font:minor-latin;
                                        mso-hansi-font-family:Calibri;
                                        mso-hansi-theme-font:minor-latin;
                                        mso-bidi-font-family:"Times New Roman";
                                        mso-bidi-theme-font:minor-bidi;}
                                    .MsoPapDefault
                                        {mso-style-type:export-only;
                                        margin-bottom:8.0pt;
                                        line-height:107%;}
                                    @page WordSection1
                                        {size:8.5in 11.0in;
                                        margin:1.0in 1.0in 1.0in 1.0in;
                                        mso-header-margin:.5in;
                                        mso-footer-margin:.5in;
                                        mso-paper-source:0;}
                                    div.WordSection1
                                        {page:WordSection1;}
                                     /* List Definitions */
                                     @list l0
                                        {mso-list-id:33890842;
                                        mso-list-template-ids:-681174524;}
                                    @list l0:level1
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0B7;
                                        mso-level-tab-stop:.5in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Symbol;}
                                    @list l0:level2
                                        {mso-level-number-format:bullet;
                                        mso-level-text:o;
                                        mso-level-tab-stop:1.0in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:"Courier New";
                                        mso-bidi-font-family:"Times New Roman";}
                                    @list l0:level3
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0A7;
                                        mso-level-tab-stop:1.5in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Wingdings;}
                                    @list l0:level4
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0A7;
                                        mso-level-tab-stop:2.0in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Wingdings;}
                                    @list l0:level5
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0A7;
                                        mso-level-tab-stop:2.5in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Wingdings;}
                                    @list l0:level6
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0A7;
                                        mso-level-tab-stop:3.0in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Wingdings;}
                                    @list l0:level7
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0A7;
                                        mso-level-tab-stop:3.5in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Wingdings;}
                                    @list l0:level8
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0A7;
                                        mso-level-tab-stop:4.0in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Wingdings;}
                                    @list l0:level9
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0A7;
                                        mso-level-tab-stop:4.5in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Wingdings;}
                                    @list l1
                                        {mso-list-id:717975915;
                                        mso-list-template-ids:-254880148;}
                                    @list l1:level1
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0B7;
                                        mso-level-tab-stop:.5in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Symbol;}
                                    @list l1:level2
                                        {mso-level-number-format:bullet;
                                        mso-level-text:o;
                                        mso-level-tab-stop:1.0in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:"Courier New";
                                        mso-bidi-font-family:"Times New Roman";}
                                    @list l1:level3
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0A7;
                                        mso-level-tab-stop:1.5in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Wingdings;}
                                    @list l1:level4
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0A7;
                                        mso-level-tab-stop:2.0in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Wingdings;}
                                    @list l1:level5
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0A7;
                                        mso-level-tab-stop:2.5in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Wingdings;}
                                    @list l1:level6
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0A7;
                                        mso-level-tab-stop:3.0in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Wingdings;}
                                    @list l1:level7
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0A7;
                                        mso-level-tab-stop:3.5in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Wingdings;}
                                    @list l1:level8
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0A7;
                                        mso-level-tab-stop:4.0in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Wingdings;}
                                    @list l1:level9
                                        {mso-level-number-format:bullet;
                                        mso-level-text:\F0A7;
                                        mso-level-tab-stop:4.5in;
                                        mso-level-number-position:left;
                                        text-indent:-.25in;
                                        mso-ansi-font-size:10.0pt;
                                        font-family:Wingdings;}
                                    ol
                                        {margin-bottom:0in;}
                                    ul
                                        {margin-bottom:0in;}
                                    -->
                                </style>
    <!--[if gte mso 10]>
    <style>
     /* Style Definitions */
     table.MsoNormalTable
        {mso-style-name:"Table Normal";
        mso-tstyle-rowband-size:0;
        mso-tstyle-colband-size:0;
        mso-style-noshow:yes;
        mso-style-priority:99;
        mso-style-parent:"";
        mso-padding-alt:0in 5.4pt 0in 5.4pt;
        mso-para-margin-top:0in;
        mso-para-margin-right:0in;
        mso-para-margin-bottom:8.0pt;
        mso-para-margin-left:0in;
        line-height:107%;
        mso-pagination:widow-orphan;
        font-size:11.0pt;
        font-family:"Calibri",sans-serif;
        mso-ascii-font-family:Calibri;
        mso-ascii-theme-font:minor-latin;
        mso-hansi-font-family:Calibri;
        mso-hansi-theme-font:minor-latin;
        mso-bidi-font-family:"Times New Roman";
        mso-bidi-theme-font:minor-bidi;}
    </style>
    <![endif]--><!--[if gte mso 9]><xml>
 <o:shapedefaults v:ext="edit" spidmax="1026"/>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <o:shapelayout v:ext="edit">
  <o:idmap v:ext="edit" data="1"/>
 </o:shapelayout></xml><![endif]-->
</head>

<body lang=EN-US link=blue vlink="#954F72" style='tab-interval:.5in'>

<div className=WordSection1>

    <p className=MsoNormal style='margin-top:.25in;margin-right:0in;margin-bottom:.25in;
margin-left:0in;line-height:36.0pt;mso-outline-level:2;background:white'><span
        style='font-size:34.0pt;font-family:"Helvetica",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#616161'>Terms &amp; Conditions<o:p></o:p></span></p>

    <p className=MsoNormal style='margin-bottom:12.0pt;line-height:18.0pt;background:
white'><span style='font-size:10.5pt;font-family:"Helvetica",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#616161'>By downloading or
using the app, these terms will automatically apply to you – you should make
sure therefore that you read them carefully before using the app. You’re not
allowed to copy, or modify the app, any part of the app, or our trademarks in
any way. You’re not allowed to attempt to extract the source code of the app,
and you also shouldn’t try to translate the app into other languages, or make
derivative versions. The app itself, and all the trade marks, copyright,
database rights and other intellectual property rights related to it, still
belong to PT Living Fitness Indonesia.<o:p></o:p></span></p>

    <p className=MsoNormal style='margin-bottom:12.0pt;line-height:18.0pt;background:
white'><span style='font-size:10.5pt;font-family:"Helvetica",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#616161'>PT Living Fitness
Indonesia is committed to ensuring that the app is as useful and efficient as
possible. For that reason, we reserve the right to make changes to the app or
to charge for its services, at any time and for any reason. We will never
charge you for the app or its services without making it very clear to you
exactly what you’re paying for.<o:p></o:p></span></p>

    <p className=MsoNormal style='margin-bottom:12.0pt;line-height:18.0pt;background:
white'><span style='font-size:10.5pt;font-family:"Helvetica",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#616161'>The LIF app stores and
processes personal data that you have provided to us, in order to provide our
Service. It’s your responsibility to keep your phone and access to the app
secure. We therefore recommend that you do not jailbreak or root your phone,
which is the process of removing software restrictions and limitations imposed
by the official operating system of your device. It could make your phone
vulnerable to malware/viruses/malicious programs, compromise your phone’s
security features and it could mean that the LIF app won’t work properly or at
all.<o:p></o:p></span></p>

    <p className=MsoNormal style='margin-bottom:12.0pt;line-height:18.0pt;background:
white'><span style='font-size:10.5pt;font-family:"Helvetica",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#616161'>You should be aware
that there are certain things that PT Living Fitness Indonesia will not take
responsibility for. Certain functions of the app will require the app to have
an active internet connection. The connection can be Wi-Fi, or provided by your
mobile network provider, but PT Living Fitness Indonesia cannot take
responsibility for the app not working at full functionality if you don’t have
access to Wi-Fi, and you don’t have any of your data allowance left.<o:p></o:p></span></p>

    <p className=MsoNormal style='margin-bottom:12.0pt;line-height:18.0pt;background:
white'><span style='font-size:10.5pt;font-family:"Helvetica",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#616161'>If you’re using the
app outside of an area with Wi-Fi, you should remember that your terms of the
agreement with your mobile network provider will still apply. As a result, you
may be charged by your mobile provider for the cost of data for the duration of
the connection while accessing the app, or other <span className=GramE>third party</span>
charges. In using the app, you’re accepting responsibility for any such
charges, including roaming data charges if you use the app outside of your home
territory (i.e. region or country) without turning off data roaming. If you are
not the bill payer for the device on which you’re using the app, please be
aware that we assume that you have received permission from the bill payer for
using the app.<o:p></o:p></span></p>

    <p className=MsoNormal style='margin-bottom:12.0pt;line-height:18.0pt;background:
white'><span style='font-size:10.5pt;font-family:"Helvetica",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#616161'>Along the same lines,
PT Living Fitness Indonesia cannot always take responsibility for the way you
use the app i.e. You need to make sure that your device stays charged – if it
runs out of battery and you can’t turn it on to avail the Service, PT Living
Fitness Indonesia cannot accept responsibility.<o:p></o:p></span></p>

    <p className=MsoNormal style='margin-bottom:12.0pt;line-height:18.0pt;background:
white'><span style='font-size:10.5pt;font-family:"Helvetica",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#616161'>With respect to PT
Living Fitness Indonesia’s responsibility for your use of the app, when you’re
using the app, it’s important to bear in mind that although we endeavor to
ensure that it is updated and correct at all times, we do rely on third parties
to provide information to us so that we can make it available to you. PT Living
Fitness Indonesia accepts no liability for any loss, direct or indirect, you
experience as a result of relying wholly on this functionality of the app.<o:p></o:p></span></p>

    <p className=MsoNormal style='margin-bottom:12.0pt;line-height:18.0pt;background:
white'><span style='font-size:10.5pt;font-family:"Helvetica",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#616161'>At some point, we may
wish to update the app. The app is currently available on Android &amp; iOS –
the requirements for both systems (and for any additional systems we decide to
extend the availability of the app to) may change, and you’ll need to download
the updates if you want to keep using the app. PT Living Fitness Indonesia does
not promise that it will always update the app so that it is relevant to you
and/or works with the Android &amp; iOS version that you have installed on your
device. However, you promise to always accept updates to the application when
offered to you, we may also wish to stop providing the app, and may terminate
use of it at any time without giving notice of termination to you. Unless we
tell you otherwise, upon any termination, (a) the rights and licenses granted
to you in these terms will end; (b) you must stop using the app, and (if
needed) delete it from your device.<o:p></o:p></span></p>

    <p className=MsoNormal style='margin-bottom:12.0pt;line-height:18.0pt;background:
white'><b><span style='font-size:10.5pt;font-family:"Helvetica",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#616161'>Changes to This Terms
and Conditions</span></b><span style='font-size:10.5pt;font-family:"Helvetica",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#616161'><o:p></o:p></span></p>

    <p className=MsoNormal style='margin-bottom:12.0pt;line-height:18.0pt;background:
white'><span style='font-size:10.5pt;font-family:"Helvetica",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#616161'>We may update our
Terms and Conditions from time to time. Thus, you are advised to review this
page periodically for any changes. We will notify you of any changes by posting
the new Terms and Conditions on this page. These changes are effective
immediately after they are posted on this page.<o:p></o:p></span></p>

    <p className=MsoNormal style='margin-bottom:12.0pt;line-height:18.0pt;background:
white'><b><span style='font-size:10.5pt;font-family:"Helvetica",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#616161'>Contact Us</span></b><span
        style='font-size:10.5pt;font-family:"Helvetica",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#616161'><o:p></o:p></span></p>

    <p className=MsoNormal style='margin-bottom:12.0pt;line-height:18.0pt;background:
white'><span style='font-size:10.5pt;font-family:"Helvetica",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#616161'>If you have any
questions or suggestions about our Terms and Conditions, do not hesitate to
contact us at support@lif.id.<o:p></o:p></span></p>

</div>

</body>

</html>`;
