import React from "react";
import './BlogPage.css';
import ReactQuill from "react-quill";
import axiosInstance from "../CommonComponent/AxiosInstane";
import moment from "moment";
import {Col, Row} from "react-bootstrap";
import SearchBar from "../Blogs/components/CommonComponent/SearchBar/SearchBar";
import CategorySideBar from "../Blogs/components/CommonComponent/CategorySideBar/CategorySideBar";
import history from "../CommonComponent/history";
import {Divider} from "antd";
import FreeDownloadSideBar from "../Blogs/components/CommonComponent/FreeDownloadSideBar/FreeDownloadSideBar";

class BlogPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            Title : '',
            Description : '',
            CreatedOn: '',
            PostedBy: '',
            searchName: '',
            blogTitle: ''
        }
        this.randomBlog = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.blogTitle !== prevState.blogTitle){
            return {blogTitle: nextProps.match.params.blogTitle}
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.blogTitle !== this.state.blogTitle){
            this.fetchBlogData(this.state.blogTitle);
        }
    }

    fetchBlogData(blogTitle) {
        blogTitle = decodeURIComponent(blogTitle);
        blogTitle = blogTitle.replace(/-/g, ' ');
        const url = `blogs?searchBy=lower(title)&searchValue=${blogTitle}`;
        console.log('blog title', blogTitle);
        axiosInstance.get(encodeURI(url)).then(json => {
            let data = json.data;
            if (data.success) {
                const blogs = data.result.data;
                if (blogs.length > 0) {
                    let blog = blogs.filter(blog => blog.Title.toLowerCase() === blogTitle);
                    const dataBlog = blog[0];
                    if (dataBlog) {
                        this.setState({Title: dataBlog.Title});
                        this.setState({Description: dataBlog.Description});
                        this.setState({CreatedOn: moment(dataBlog.CreatedAt).format('MMM D, YYYY')});
                        this.setState({PostedBy: dataBlog.CreatedBy});
                    }
                }
            }
        });
    }
    componentDidMount() {
        let blogTitle = this.props.match.params.blogTitle;
        this.fetchBlogData(blogTitle);
    }

    render() {
        return(
            <div className='BlogPage'>
                <Row>
                    <Col md={9} sm={12} xs={12}>
                        <div className='auto-margin card-spacer border-right'>
                            <div className='blogs-title center-label auto-margin'>
                                {this.state.Title}
                            </div>
                            <div className='posted-by'>
                                Posted on <span style={{color: 'red'}}>{this.state.CreatedOn}</span> | by <span style={{color: 'red'}}>{this.state.PostedBy}</span>
                            </div>
                            <div className='content-spacer'>
                                <ReactQuill readOnly={true} value={this.state.Description} theme={'bubble'}/>
                            </div>
                        </div>
                        <Divider/>
                        <div className='auto-margin'>
                            <div className='content-spacer'>
                                <FreeDownloadSideBar/>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} sm={12} xs={12} className='card-spacer'>
                        <SearchBar value={this.state.searchName}
                                   searchCallbackOnChange={e => this.setState({searchName: e.target.value})}
                                   onSearchClick={() => {
                                       let searchQuery = encodeURIComponent(this.state.searchName);
                                       history.push('/blogs?search=' + searchQuery)
                                   }
                                   }/>
                        <CategorySideBar categoryCallbackAction={cat => history.push('/blogs?category=' + cat)}/>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default BlogPage;
