import hReact from 'react';
import PropTypes from 'prop-types';
import './ContactUs.css';
import contactUsIcon from "../../../assets/contact-us-icon.svg";
import GeneralButton from "../GeneralButton/GeneralButton";
import history from "../history";


const ContactUs = () => {
    return (
        <GeneralButton icon={contactUsIcon} divider={true} label={'Contact Us'}
                       onClickAction={() => window.parent.location.href ='https://lif.id/contactus'}></GeneralButton>
    );
};

ContactUs.propTypes = {};

ContactUs.defaultProps = {};

export default ContactUs;
