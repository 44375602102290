import React from "react";
import './CorporateWellnessProgram.css';
import '../../../App.css';
import TitleCard from "./components/TitleCard/TitleCard";
import WhatIsCWP from "./components/WhatIsCWP/WhatIsCWP";
import FrameworkCard from "./components/FrameworkCard/FrameworkCard";
import LifDifferentCard from "./components/LifDifferentCard/LifDIfferentCard";
import ContactUsCard from "../../CommonComponent/ContactUsCard/ContactUsCard";

class CorporateWellnessProgram extends React.Component {
    constructor() {
        super();
    }
    render() {
        return(
            <div className='cwp-container'>
                <TitleCard/>
                <WhatIsCWP/>
                <FrameworkCard/>
                <LifDifferentCard/>
                <div className='card-spacer'>
                    <ContactUsCard label={'Let’s celebrate fitness together!'} />
                    <ContactUsCard label={'Are you a wellness, nutrition or mental health practitioner? Become our partner'} bgColor='#F1B61C' />
                </div>
            </div>
        )
    }
}

export default CorporateWellnessProgram;
