import React from "react";
import './AboutLIF.css';
import Description from "./components/Description/Description";
import CoreValue from "./components/CoreValue/CoreValue";
import ContactUsCard from "../CommonComponent/ContactUsCard/ContactUsCard";
import LifMaps from "./components/LifMaps/LifMaps";

const AboutLIF=() => (
    <div className='AboutLIF'>
        <Description/>
        <CoreValue/>
        <LifMaps/>
        <div className='card-spacer'>
            <ContactUsCard label={'Let’s celebrate fitness together!'} />
            <ContactUsCard label={'Are you a wellness, nutrition or mental health practitioner? Become our partner'} bgColor='#F1B61C' />
        </div>
    </div>
);

export default AboutLIF;
