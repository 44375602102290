import React, {Component} from "react";
import './LifMaps.css';
import {Col} from "react-bootstrap";
import GoogleMapReact from 'google-map-react';
import env from "../../../../config/env";

class LifMaps extends Component {
    static defaultProps = {
        center: {
            lat: -6.161696028624508,
            lng: 106.81986168704461
        },
        zoom: 16
    };

    renderMarkers(map, maps) {
        let marker = new maps.Marker({
            position: this.props.center,
            map,
            title: 'LIF'
        });
    };

    render() {
        return (
            <Col md={10} sm={12} xs={12} className='auto-margin card-spacer'>
                <div className='address-container'>
                    <div className='content-title label-align'>
                        Jakarta, Indonesia
                    </div>
                    <div className='content-label label-align'>
                        16, Jl. Hayam Wuruk No.27, RT.14/RW.1, Kb. Klp. Kecamatan Gambir, Kode Pos 10120
                    </div>
                </div>
                <div className='maps-container'>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: env.GOOGLE_KEY}}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                    >
                </GoogleMapReact>
                </div>
            </Col>
        );
    }
};

export default LifMaps;
