import React from "react";
import './CsrTitle.css';
import {Col, Row} from "react-bootstrap";
import csrTitleBg from '../../../../../assets/csr-title-bg.png';

const CsrTitle=() =>(
    <Col md={12} className='csr-title'>
        <Row>
            <Col md={{span: 5, offset: 1}} sm={12} xs={12} className='solution-title grey-font-color get-fit-margin title-line-height'>
                Get fit, challenge your people, <div className='large-page-title red-font-color'>raise awareness and funds</div> for a greate cause
            </Col>
            <Col md={6} sm={12} xs={12}>
                <img className='image' src={csrTitleBg}/>
            </Col>
        </Row>
    </Col>
);

export default CsrTitle;
