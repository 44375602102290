import React from "react";
import './WhatAssesmentCan.css';
import {Col, Row} from "react-bootstrap";
import whatIsAssesmentImg from '../../../../../assets/with-assesment-can-img.png';
import BulletList from "../../../../CommonComponent/BulletList/BulletList";

const AssesmentItem = ({title,label, label1}) =>(
    <div className='assesment-item'>
        <div className='content-title grey-font-color'>
            {title}
        </div>
        <BulletList dotSize={8} dotColor={'#439D8E'} datas={[label, label1]}/>
    </div>
);

const WhatAssesmentCan = () => (
    <Col md={10} className='auto-margin card-spacer'>
        <div className='card-title red-font-color center-label auto-margin'>
            WITH THE LIFESTYLE ASSESMENT, YOU CAN:
        </div>
        <Row className='card-spacer'>
            <Col md={6} xs={12} sm={12}>
                <img className='what-is-assesment-can-img' src={whatIsAssesmentImg}/>
            </Col>
            <Col md={6} xs={12} sm={12} className='align-self-center'>
                <AssesmentItem title={'Manage Stress'} label={'Identify activities that cause stress Perform better'} label1={'Perform better'}/>
                <AssesmentItem title={'Enhance Recovery'} label={'Understand how to improve your sleep quality'} label1={'Boost energy levels'}/>
                <AssesmentItem title={'Maintain your body composition to stay healthy:'} label={'Evaluate your body water, protein, mineral and body fatmass'} label1={' Analyze your BMI, Visceral fat, and many more'}/>
            </Col>
        </Row>
    </Col>
);

export default WhatAssesmentCan
