import axios from "axios";
import env from '../../config/env';

const axiosInstance = axios.create({
    baseURL: env.BASE_URL,
    timeout: 180000,
    headers: {'Content-Type': 'application/json'}
});

export default axiosInstance;

