import React from "react";
import './RecentPostSideBar.css';
import arrowRight from "../../../../../assets/right-arrow.svg";
import axiosInstance from "../../../../CommonComponent/AxiosInstane";
import history from "../../../../CommonComponent/history";

const Post = ({title, clickAction}) => (
    <div onClick={clickAction} className='column-container post-container'>
        <img src={arrowRight}/>
        <div className='post-lbl ml-3'>
            {title}
        </div>
    </div>
)

class RecentPostSideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blogData: []
        }
    }

    componentDidMount() {
        axiosInstance.get('api/website/recent/4').then(json => {
            console.log(json);
            var datas = json.data.WebsiteBlogs;
            this.setState({blogData: datas});
        });
    }

    redirectIntoBlog(blogData) {
        var titleUrl = blogData.Title.toLowerCase().replace(/ /g, "-");
        titleUrl = encodeURIComponent(titleUrl);
        const url = `/blogs/${titleUrl}`;
        history.push(url);
    }

    blogLink(data) {
        var slug = data.Title.toLowerCase().replace(/ /g, "-");
        window.parent.location.href = "/blogs/"+slug;
    }

    render() {
        return (
            <div>
                <div className='content-spacer content-title red-font-color ml-4'>
                    Recent Posts
                </div>
                <div className='content-spacer'>
                    {this.state.blogData.map((blog, index) => {
                            return (
                                <Post title={blog.Title} clickAction={() => this.blogLink(blog)}/>
                            )
                        }
                    )
                    }
                </div>
            </div>
        )
    }
}

export default RecentPostSideBar;
