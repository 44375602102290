import React from 'react';
import './TitleCard.css';
import secondTitleImg from '../../../../../assets/cwp-title-bg.png';
import {Col,Row} from "react-bootstrap";

const TitleCard = () =>(
    <Col md={12} className='SecondTitleCard'>
        <Row>
            <Col md={{span: 5, offset: 1}} sm={12} xs={12} className='solution-title title-margin title-line-height grey-font-color'>
                Empower your people with <div className='large-page-title red-font-color'>Engaging and Fun</div> Wellness program
                experience
            </Col>
            <Col md={6} sm={12} xs={12}>
                <img className='img-fluid float-right' src={secondTitleImg}/>
            </Col>
        </Row>
    </Col>
);

export default TitleCard;
