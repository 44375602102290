import React from 'react';
import {Col, Row} from "react-bootstrap";
import './ImpactCard.css';
import '../../../../App.css';
import {isMobile} from 'react-device-detect';
import ImpactCardPeople from '../../../../assets/impact-card-people-2.png';
import CardImproveImg from '../../../../assets/card-improve.png';
import CardEngagementImg from '../../../../assets/card-engagement.png';
import CardHealthCostImg from '../../../../assets/reduce-healthcost.png';
import CardTeamWorkImg from '../../../../assets/card-teamwork.png';

const Card = ({image, title, subtitle, label}) => (
    <Col className='card-container'>
        <img className='card-image' src={image}/>
        <div className='content-title grey-font-color center-label'>{title}{!isMobile &&
        <br/>}{' '+subtitle}</div>
        <div className='content-label semi-black-font-color justify-content-center center-label'>{label}</div>
    </Col>
);

const ImpactCard = () => (
    <Col md={10} className='justify-content-between auto-margin card-spacer'>
        <Row>
            <Col md={5} sm={12} className='column-container'>
                <div className='align-self-center'>
                    <img className='img-fluid' src={ImpactCardPeople}/>
                </div>
            </Col>
            <Col md={7} sm={12} className='mt-1'>
                <Row className='card-title justify-content-center red-font-color'>Know the impacts of having us!</Row>
                <Row>
                    <Col md={6} sm={12}>
                        <Card image={CardImproveImg} title={'Improve'} subtitle={'Business Performance'}
                              label={'Take care of your people by making them stay healthy and be more productive'}></Card>
                    </Col>
                    <Col md={6} sm={12}>
                        <Card image={CardEngagementImg} title={'Improve'} subtitle={'Employee Engagement'}
                              label={'Keep your people connected in a fun way through community engagement powered by technology'}></Card>
                    </Col>
                    <Col md={6} sm={12}>
                        <Card image={CardHealthCostImg} title={'Reduce'} subtitle={'Healthcare Cost'}
                              label={'Adopting healthy habits within their work-life surely brings down the overall healthcare costs'}></Card>
                    </Col>
                    <Col md={6} sm={12}>
                        <Card image={CardTeamWorkImg} title={'Better Teamwork'} subtitle={'and Leadership'}
                              label={'Build positive atmosphere around your organisation to boost their energy and confidence'}></Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Col>
)

export default ImpactCard;
