import React from 'react';
import PropTypes from 'prop-types';
import './ContactUsCard.css';
import '../../../App.css';
import {Col, Row} from "react-bootstrap";
import ContactUs from "../ContactUs/ContactUs";

const ContactUsCard = ({label, bgColor}) => (
    <Col md={10} sm={11} xs={11} className='contact-us-container flex-column auto-margin' style={{backgroundColor: bgColor}}>
        <Row className='max-height align-items-center'>
            <Col md={9} sm={12} xs={12} className='content-title vertical-auto label-align'>
                {label}
            </Col>
            <Col md={3} sm={12} xs={12} className='column-container'>
                <div className='auto-margin'>
                    <ContactUs></ContactUs>
                </div>
            </Col>
        </Row>
    </Col>
);

ContactUsCard.propTypes = {
    label: PropTypes.string,
    bgColor: PropTypes.string,
};
ContactUsCard.defaultProps = {
    label: 'Find out what LIF can do for your workplace!',
    bgColor: '#439D8E'
};

export default ContactUsCard;
