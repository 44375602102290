import React from "react";
import './TermOfService.css';
import '../../../App.css';
import {Col} from "react-bootstrap";
import NumberingList from "../../CommonComponent/NumberingList/NumberingList";

const TermOfService=()=>(
    <Col md={10} sm={12} xs={12} className='TermOfService auto-margin content-spacer'>
        <div className='large-page-title red-font-color'>
            TERMS OF SERVICE
        </div>
        <div className='number-margin content-title grey-font-color content-spacer'>
            1. Introduction
        </div>
        <div className='content-label semi-black-font-color content-spacer'>
            Welcome to PT Living Fitness Indonesia (“LIF”, “Company”, “we”, “our”, “us”)!
            These Terms of Service (“Terms”, “Terms of Service”) govern your use of our website
            located at lif.id (together or individually “Service”) operated by PT Living Fitness Indonesia.
            Our Privacy Policy also governs your use of our Service and explains how we collect,
            safeguard and disclose information that results from your use of our web pages.
            Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You
            acknowledge that you have read and understood Agreements, and agree to be bound of them.
            If you do not agree with (or cannot comply with) Agreements, then you may not use the Service,
            but please let us know by emailing at support@lif.id so we can try to find a solution. These Terms
            apply to all visitors, users and others who wish to access or use Service.
        </div>
        <div className='number-margin content-title grey-font-color content-spacer'>
            2. Communications
        </div>
        <div className='content-label semi-black-font-color content-spacer'>
            By using our Service, you agree to subscribe to newsletters, marketing or promotional materials
            and other information we may send. However, you may opt out of receiving any, or all, of these
            communications from us by following the unsubscribe link or by emailing at support@lif.id.
        </div>
        <div className='number-margin content-title grey-font-color content-spacer'>
            3. Contests, Sweepstakes and Promotions
        </div>
        <div className='content-label semi-black-font-color content-spacer'>
            Any contests, sweepstakes or other promotions (collectively, “Promotions”) made available through
            Service may be governed by rules that are separate from these Terms of Service. If you participate in
            any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for
            a Promotion conflict with these Terms of Service, Promotion rules will apply
        </div>
        <div className='number-margin content-title grey-font-color content-spacer'>
            4. Content
        </div>
        <div className='content-label semi-black-font-color content-spacer'>
            Our Service allows you to post, link, store, share and otherwise make available certain information, text,
            graphics, videos, or other material (“Content”). You are responsible for Content that you post on or
            through Service, including its legality, reliability, and appropriateness.
            By posting Content on or through Service, You represent and warrant that: (i) Content is yours (you own it)
            and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms,
            and (ii) that the posting of your Content on or through Service does not violate the privacy rights, publicity rights,
            copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminat
            the account of anyone found to be infringing on a copyright.
            You retain any and all of your rights to any Content you submit, post or display on or through Service and
            you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through Service. However, by posting Content using Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through Service. You agree that this license includes.
            the right for us to make your Content available to other users of Service, who may also use your Content subject to these Terms.
            PT Living Fitness Indonesia has the right but not the obligation to monitor and edit all Content provided by users.
            In addition, Content found on or through this Service are the property of PT Living Fitness Indonesia or used with permission.
            You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part,
            for commercial purposes or for personal gain, without express advance written permission from us.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Advertising Partners Privacy Policies
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:
            <NumberingList datas={['In any way that violates any applicable national or international law or regulation.','For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.','To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.','To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.','In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.','To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.']} />
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            Additionally, you agree not to:
            <NumberingList datas={['Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.','Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.','Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.','Use any device, software, or routine that interferes with the proper working of Service.','Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.','Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is Service is stored, or any server, computer, or database connected to Service.','Attack Service via a denial-of-service attack or a distributed denial-of-service attack.','Take any action that may damage or falsify Company rating.','Otherwise attempt to interfere with the proper working of Service.']} />
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Analytics
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            We may use third-party Service Providers to monitor and analyze the use of our Service.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            No Use By Minors
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using Service, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the access and usage of Service.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Accounts
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete,
            or obsolete information may result in the immediate termination of your account on Service.
            You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming
            aware of any breach of security or unauthorized use of your account.
            You may not use as a username the name of another person or entity or that is not lawfully available
            for use, a name or trademark that is subject to any rights of another person or entity other
            than you, without appropriate authorization. You may not use as a username any name
            that is offensive, vulgar or obscene.
            We reserve the right to refuse service, terminate
            accounts, remove or edit content, or cancel orders in our sole discretion.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Intellectual Property
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of PT Living Fitness Indonesia and its licensors. Service is protected by copyright, trademark, and other laws of and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of PT Living Fitness Indonesia.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Copyright Policy
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            We respect the intellectual property rights of others. It is our policy to respond to any
            claim that Content posted on Service infringes on the copyright or other intellectual
            property rights (“Infringement”) of any person or entity.
            If you are a copyright owner, or authorized on behalf of one, and you believe that
            the copyrighted work has been copied in a way that constitutes copyright infringement, please
            submit your claim via email to support@lif.id, with the subject line: “Copyright Infringement” and
            include in your claim a detailed description of the alleged Infringement as detailed below, under
            “DMCA Notice and Procedure for Copyright Infringement Claims”
            You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            DMCA Notice and Procedure for Copyright Infringement Claims
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
            <NumberingList datas={['an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright’s interest;','a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work;','identification of the URL or other specific location on Service where the material that you claim is infringing is located;','your address, telephone number, and email address;','a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;','a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf. You can contact our Copyright Agent via email at support@lif.id.']} />
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Error Reporting and Feedback
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            You may provide us either directly at support@lif.id or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the
            Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates
            an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual
            right to use (including copy, modify, create derivative works, publish, distribute
            and commercialize) Feedback in any manner and for any purpose.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Links To Other Web Sites
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            Our Service may contain links to third party web sites or services that
            are not owned or controlled by PT Living Fitness Indonesia.
            PT Living Fitness Indonesia has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings
            of any of these entities/individuals or their websites.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Termination
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            We may terminate or suspend your account and bar access to Service immediately,
            without prior notice or liability, under our sole discretion, for any reason whatsoever
            and without limitation, including but not limited to a breach of Terms.
            If you wish to terminate your account, you may simply discontinue using Service.
            All provisions of Terms which by their nature should survive termination shall survive
            termination, including, without limitation, ownership provisions,
            warranty disclaimers, indemnity and limitations of liability.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Governing Law
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            These Terms shall be governed and construed in accordance with the laws of Indonesia,
            which governing law applies to agreement without regard to its conflict of law provisions.
            Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
            If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Changes To Service
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            We reserve the right to withdraw or amend our Service, and any service or material we provide via
            Service, in our sole discretion without notice. We will not be liable if for any reason all or any part
            of Service is unavailable at any time or for any period. From time to time, we may restrict access
            to some parts of Service, or the entire Service, to users, including registered users.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Amendments To Terms
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            We may amend Terms at any time by posting the amended terms on this site. It is your
            responsibility to review these Terms periodically.
            Your continued use of the Platform following the posting of revised Terms means that
            you accept and agree to the changes. You are expected to check this page frequently
            so you are aware of any changes, as they are binding on you.
            By continuing to access
            or use our Service after any revisions become effective, you agree to be bound by
            the revised terms. If you do not agree to the new terms,
            you are no longer authorized to use Service.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Acknowledgement
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            By using service or other services provided by us, you acknowledge that you have read these terms of service and agree to be bound by them.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Contact Us
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            Please send your feedback, comments, requests for technical support by email: support@lif.id.
        </div>

    </Col>
);

export default TermOfService;
