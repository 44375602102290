import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "react-bootstrap";
import './FrameworkCard.css';
import healthAssesmentLogo from '../../../../../assets/health-assesment-logo.png';
import activityTrackerLogo from '../../../../../assets/activity-tracker-logo.png';
import fitnessChallengeLogo from '../../../../../assets/fitness-challenge-logo.png';
import lifReward from '../../../../../assets/reward-icon.svg';
import healthEducationLogo from '../../../../../assets/health-education.png';
import eventsLogo from '../../../../../assets/events-logo.png';
import coachingLogo from '../../../../../assets/coaching-logo.png';
import reportLogo from '../../../../../assets/report-logo.png';

class Framework extends Component {
    constructor() {
        super();
        this.state = {isHovered: false};
        this.toggleHover = this.toggleHover.bind(this);
    }

    toggleHover() {
        this.setState(prevState => ({isHovered: !prevState.isHovered}));
    }

    render() {
        return (
            <div className='framework-container' onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                <Col className='framework-content align-self-center'>
                    <img className='framework-logo block-disp auto-margin' src={this.props.image}/>
                    <div className='content-title grey-font-color center-label'
                         style={{color: '439D8E'}}>{this.props.title}</div>
                </Col>
                {this.state.isHovered && <div className='content-container'>
                    <Col className='align-self-center'>
                        <div className='content-word-smaller center-label' style={{color:'white'}}>
                            {this.props.label}
                        </div>
                    </Col>
                </div>
                }
            </div>
        );
    }
};

const FrameworkCard = () => (
    <Col md={10} sm={12} xs={12} className='card-spacer auto-margin'>
        <div className='card-title justify-content-center center-label' style={{color: '#DE1E30'}}>OUR FRAMEWORK</div>
        <Row className='card-spacer auto-margin'>
            <Col md={3} sm={6} xs={6} className='auto-margin no-padding'>
                <Framework image={healthAssesmentLogo} title={'Health Risk Assesment'}
                           label={'We make our best effort to identify your employee health risk by performing body composition measurement, questionnaire, and datamine your company’s data to address your needs and goals.'}></Framework>
            </Col>
            <Col md={3} sm={6} xs={6} className='auto-margin no-padding'>
                <Framework image={activityTrackerLogo} title={'Apps & Activity Tracker'}
                           label={'Our tracker is a versatile tool that will keep track of employee\'s step, heart rates, and sleeping habits. Our tracker is also accompanied with a state of the art mobile app; available at iOS Appstore and Android Playstore.'}></Framework>
            </Col>
            <Col md={3} sm={6} xs={6} className='auto-margin no-padding'>
                <Framework image={fitnessChallengeLogo} title={'Fitness Challenge'}
                           label={'LIF Corporate Challenge is designed to encourage good behavioural changes to your company. We have Individual and team challenges to keep your employees active and connected with their peers.'}></Framework>
            </Col>
            <Col md={3} sm={6} xs={6} className='auto-margin no-padding'>
                <Framework image={lifReward} title={'LIF Rewards'}
                           label={'LIF rewards are effective tools to initiate and to sustain participations while eliminating undesired behaviours. This is our ultimate spearhead to elevate good culture at your workplace.'}></Framework>
            </Col>
            <Col md={3} sm={6} xs={6} className='auto-margin no-padding'>
                <Framework image={healthEducationLogo} title={'Health Education'}
                           label={'Health education raises employee’s knowledge about wellness through various media. Our curriculum will be their companion in raising motivation, team spirit and maintaining a healthy lifestyle.'}></Framework>
            </Col>
            <Col md={3} sm={6} xs={6} className='auto-margin no-padding'>
                <Framework image={eventsLogo} title={'Wellness Events'}
                           label={'Promoting a healthy lifestyle has lost its credibility due to dull and poor events. Our team of professionals are ready to create your event more fun and memorable. You name it, we create it!'}></Framework>
            </Col>
            <Col md={3} sm={6} xs={6} className='auto-margin no-padding'>
                <Framework image={coachingLogo} title={'Program Coaching'}
                           label={'Let our team of professional coaches help your employees build trust and confidence in their multidimensional journey to achieve healthy lifestyle and happiness. We leave no man behind!'}></Framework>
            </Col>
            <Col md={3} sm={6} xs={6} className='auto-margin no-padding'>
                <Framework image={reportLogo} title={'Comprehensive Report'}
                           label={'Our reports are designed with you as the center. We provide a comprehensive and tailorable report right to your company’s doorstep'}></Framework>
            </Col>

        </Row>
    </Col>
);

export default FrameworkCard;
