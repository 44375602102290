import React from "react";
import './CommentsCard.css';
import {comments} from "./CommentCardConst";
import {isMobile} from 'react-device-detect';
import clsx from "clsx";
import {Col} from "react-bootstrap";

const Comment = ({comment, org, name, position}) =>{
    return(
        <div className='comment-container p-3 m-1 d-flex flex-column justify-content-between'>
            <div className='comment-lbl lif-grey'>
                {comment}
            </div>
            <div className='mt-3'>
                <div className='name-lbl lif-black'>
                    {name}
                </div>
                <div className='org-lbl lif-orange'>
                    <div className='lif-black'>{position}</div>
                    <div>{org}</div>
                </div>
            </div>
        </div>
    )
}

const CommentsCard = ({}) =>{
    return(
        <div className='col-12 auto-margin padding-card'>
            <Col className='p-3 auto-margin' md={6} sm={12} xs={12}>
                <div className='circle-dot'/>
                <div className='subtitle-emp-lbl lif-magenta text-center'>
                    Healthy Life {isMobile && <br/>} Changes Everything
                </div>
                <div className='subtitleLbl text-center mt-2'>
                    Learn how LIF brought behaviour change to our clients
                </div>
                <div className='circle-dot-small'/>
            </Col>
            <Col className='mt-5 auto-margin d-flex flex-md-row flex-column-xs flex-wrap' md={10} sm={12} xs={12}>
                {comments.map(value => <Comment name={value.name} position={value.position} comment={value.comment} org={value.org}/>)}
            </Col>
        </div>
    )
}
export default CommentsCard;
