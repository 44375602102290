import React, {useState} from "react";
import './BoostEmployee.css';
import {Col, Form} from "react-bootstrap";
import GetStartedButton from "../GetStarted/GetStarted";
import {isMobile} from 'react-device-detect';

const BoostEmployee = ({}) =>{
    const [email, setEmail] = useState('');
    return(
        <div className='boost-containter padding-card col-md-12'>
            <Col md={12} sm={10} xs={10} className='auto-margin'>
                <div className='boost-lbl mt-2 text-center lif-cocrol'>
                    Are you ready to {isMobile && <br/>} <b>boost your employees?</b>
                </div>
                <div className='col-md-6 auto-margin mt-4 d-flex flex-row align-items-center'>
                    {!isMobile && <Form.Control value={email} onChange={event => setEmail(event.target.value)} placeholder={'name@yourcompany.com'}  className='rounded-corner general-form' type="text"/>}
                    <div className='ml-button'>
                        <GetStartedButton emailTo={email}/>
                    </div>
                </div>
            </Col>
        </div>
    )
}

export default BoostEmployee;
