import React from "react";
import './HowItWorks.css';
import campaignImg from '../../../../../assets/campaign-img.png';
import fitnessImg from '../../../../../assets/fitness-img.svg';
import storyImg from '../../../../../assets/story-img.svg';
import participantStepsImg from '../../../../../assets/participant-steps-img.svg';
import goalImg from '../../../../../assets/goal-img.svg';
import {Col, Row} from "react-bootstrap";

const HowItCards = ({image, label}) => (
    <Col md={2} sm={12} xs={12} className='mt-5'>
        <img className='card-img' src={image}/>
        <Row>
            <Col md={12} sm={8} xs={8} className='mt-2 auto-margin content-label center-label semi-black-font-color'>{label}</Col>
        </Row>
    </Col>
);

const HowItWorks = () => (
    <Col md={10} sm={12} xs={12} className='card-spacer card-title center-label red-font-color auto-margin'>
        HOW IT WORKS
        <Row className='content-spacer justify-content-between'>
            <HowItCards image={campaignImg} label={'Create a campaign and set your steps & fundraising target'}/>
            <HowItCards image={fitnessImg} label={'Use our fitness tracker and login to LIF Apps'}/>
            <HowItCards image={storyImg} label={'Share story and raise awareness'}/>
            <HowItCards image={participantStepsImg} label={'Track all participants steps anywhere and anytime on dedicated website'}/>
            <HowItCards image={goalImg} label={'Complete the goal and donate the result based on total steps'}/>
        </Row>
    </Col>
);

export default HowItWorks;
