import React from "react";
import './PrivacyPolicy.css';
import {Col} from "react-bootstrap";
import '../../../App.css';
import BulletList from "../../CommonComponent/BulletList/BulletList";

const PrivacyPolicyApp = () => (
    <Col md={10} sm={12} xs={12} className='PrivacyPolicy auto-margin'>
        <div className='content-spacer large-page-title red-font-color'>
            Privacy Policy
        </div>
        <div className='content-title grey-font-color'>
            for PT Living Fitness Indonesia
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            At LIF Indonesia, accessible from lif.id, one of our main priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and recorded by LIF Indonesia and how we use it.
            If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
            This Privacy Policy applies only to our online activities and is valid for visitors to our website
            with regards to the information that they shared and/or collect in LIF Indonesia. This policy is not
            applicable to any information collected offline or via channels other than this website.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Consent
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Information we collect
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            The personal information that you are asked to provide, and the reasons why you are asked to provide it,
            will be made clear to you at the point we ask you to provide your personal information.

            If you contact us directly, we may receive additional information about you such as your name,
            email address, phone number, the contents of the message and/or attachments you may send us,
            and any other information you may choose to provide.

            When you register for an Account, we may ask for your contact information, including items
            such as name, company name, address, email address, and telephone number.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            How we use your information
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            <BulletList datas={['We use the information we collect in various ways, including to:','Provide, operate, and maintain our website',
            'Improve, personalize, and expand our website','Understand and analyze how you use our website',
            'Develop new products, services, features, and functionality','Communicate with you, either directly or through one of our partners,\n        including for customer service, to provide you with updates and other\n        information relating to the website, and for marketing and promotional purposes',
            'Send you emails','Find and prevent fraud']} dotSize={10} dotColor={'#4A4F53'}/>
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Cookies and Web Beacons
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            Like any other website, LIF Indonesia uses 'cookies'. These cookies are used to store information including visitors'
            preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize
            the users' experience by customizing our web page content based on visitors' browser type and/or other information.
            For more general information on cookies, please read “What Are Cookies" from Cookie Consent.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Advertising Partners Privacy Policies
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            Like any other website, LIF Indonesia uses 'cookies'. These cookies are used to store information including visitors'
            preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize
            the users' experience by customizing our web page content based on visitors' browser type and/or other information.
            For more general information on cookies, please read “What Are Cookies" from Cookie Consent.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Third Party Privacy Policies
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            LIF Indonesia's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult
            the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their
            practices and instructions about how to opt-out of certain options.
            You can choose to disable cookies through
            your individual browser options. To know more detailed information about cookie management with specific
            web browsers, it can be found at the browsers' respective websites.
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Data Protection Rights
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            <BulletList
                datas={['The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.', 'The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.', 'The right to erasure – You have the right to request that we erase your personal data, under certain conditions.', 'The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.', 'The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.', 'The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.', 'If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.']}
                dotSize={10} dotColor={'#4A4F53'}/>
        </div>
        <div className='content-title grey-font-color content-spacer'>
            Contacting Us
        </div>
        <div className='content-spacer content-label semi-black-font-color'>
            We welcome any queries, comments or requests you may have regarding this Privacy Policy.
            Please do not hesitate to contact us at <a href="mailto:support@lif.id">support@lif.id</a>
        </div>
    </Col>
);

export default PrivacyPolicyApp;
